import { useField } from 'formik';
import styled from 'styled-components';

import {
  APIDestination,
  DestinationAuthMethodConfig,
  DestinationAuthMethodType,
  DestinationHTTPMethod,
  DestinationRateLimitPeriod,
} from '../../../../../../../../typings/Destination.interface';
import { fieldName, isValidUrl } from '../../../../../utils';
import { ClickableArea } from '../../../../common/base/Button';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';
import resource_details_form_props from './resource_details';
import { SelectDestinationType } from '../../GuidedConnection/Steps/SelectDestinationType';
import Alert from '../../../../common/base/Alert';
import { DestinationType } from '../../../../../../../../typings/Integration.interface';
import { useContext } from 'react';
import { DashboardContext } from '../../DashboardContext';
import { DestinationFeature, DestinationFeatureType, useFeatures } from './form_features';
import { cleanseFormErrorObject } from '../../../../../utils/form';

type ErrorOfFormValue<T> = {
  [P in keyof T]?: T[P] extends object ? ErrorOfFormValue<T[P]> : string | object;
};

export interface DestinationConfigurationFormValues {
  type?: DestinationType;
  enable_rate_limit: boolean;
  enable_custom_http_method: boolean;
  show_advanced: boolean;
  config: {
    rate_limit?: string;
    rate_limit_period?: DestinationRateLimitPeriod;
    http_method?: DestinationHTTPMethod | null;
    path_forwarding: boolean;
    url?: string | null;
    path?: string | null;
    auth?: DestinationAuthMethodConfig;
    auth_type?: DestinationAuthMethodType;
  };
}

const StyledHR = styled.hr`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.outline.neutral};
  border: none;
`;

const destination_configuration_form_props = {
  postprocessValues: (values: DestinationConfigurationFormValues) => {
    return {
      type: values.type,
      config: {
        path: values.type === 'CLI' ? values.config.path : null,
        http_method: values.enable_custom_http_method ? values.config.http_method : null,
        url: values.config.url || null,
        rate_limit: values.enable_rate_limit ? Number(values.config.rate_limit) : null,
        rate_limit_period: values.enable_rate_limit ? values.config.rate_limit_period : null,
        path_forwarding_disabled: !values.config.path_forwarding,
        auth_type: values.config.auth_type || null,
        auth: values.config.auth || null,
      },
    };
  },
  getInitialValues: (destination?: APIDestination): DestinationConfigurationFormValues => {
    if (destination) {
      return {
        type: destination.type as DestinationType | undefined,
        enable_rate_limit:
          'rate_limit' in destination.config && destination.config.rate_limit ? true : false,
        enable_custom_http_method:
          'http_method' in destination.config && destination.config.http_method ? true : false,
        show_advanced:
          !!destination.config.path_forwarding_disabled ||
          !!destination.config.http_method ||
          destination.config.auth_type !== 'HOOKDECK_SIGNATURE',
        config: {
          ...(destination.config || {}),
          http_method: destination.config.http_method || 'POST',
          rate_limit:
            'rate_limit' in destination.config ? String(destination.config.rate_limit) : undefined,
          rate_limit_period:
            'rate_limit_period' in destination.config
              ? destination.config.rate_limit_period
              : 'second',
          path_forwarding:
            'path_forwarding_disabled' in destination.config
              ? !destination.config.path_forwarding_disabled
              : true,
          auth_type: destination.config.auth_type || 'HOOKDECK_SIGNATURE',
          auth: destination.config.auth || {},
        } as DestinationConfigurationFormValues['config'],
      };
    }
    return {
      type: undefined,
      enable_rate_limit: false,
      enable_custom_http_method: false,
      show_advanced: false,
      config: {
        http_method: 'POST',
        path_forwarding: true,
        rate_limit: undefined,
        rate_limit_period: 'second',
        auth_type: 'HOOKDECK_SIGNATURE',
        auth: {},
      },
    };
  },
  validate: async (values: DestinationConfigurationFormValues) => {
    const errors: ErrorOfFormValue<DestinationConfigurationFormValues> = {};
    if (!values.type) {
      errors.type = 'Required';
    }
    if (values.type === 'HTTP') {
      if (!values.config?.url || values.config?.url.length === 0) {
        errors.config = errors.config
          ? { ...(errors.config as object), url: 'Required' }
          : { url: 'Required' };
      }
      if (values.config?.url) {
        if (!isValidUrl(values.config.url)) {
          errors.config = errors.config
            ? { ...(errors.config as object), url: 'Must be a valid HTTP url' }
            : { url: 'Must be a valid HTTP url' };
        }
      }
    } else if (values.type === 'CLI') {
      if (!values.config?.path || values.config?.path.length === 0) {
        errors.config = errors.config
          ? { ...(errors.config as object), path: 'Required' }
          : { path: 'Required' };
      }

      if (
        values.config?.path &&
        !/^\/[0-9a-zA-Z\/\^\?\&\'\@\{\}\[\]\,\$\=\!\-\#\(\)\.\%\+\~_]*$/.test(values.config.path)
      ) {
        errors.config = errors.config
          ? { ...(errors.config as object), path: 'Must be a valid pathname and start with "/"' }
          : {
              path: 'Must be a valid pathname and start with "/"',
            };
      }
    }

    if (values.enable_rate_limit && Number(values.config?.rate_limit) <= 0) {
      errors['config.rate_limit'] = 'Must be greater than 0';
    }

    return cleanseFormErrorObject(errors);
  },
  Fields: ({ prefix, disable_name }: { prefix: string; disable_name?: boolean }) => {
    const { destination_types } = useContext(DashboardContext);
    const [{ value: show_advanced }, , { setValue: setShowAdvanced }] = useField<boolean>(
      fieldName('show_advanced', prefix),
    );
    const [{ value: type }] = useField<DestinationType>(fieldName('type', prefix));

    const selected_type = destination_types?.[type];
    const configuration_alert = selected_type?.configuration_alert;
    const { required_features, advanced_features } = useFeatures(
      selected_type?.features || {},
      'auth_method',
    );

    return (
      <>
        <SelectDestinationType key={type} prefix={prefix} />
        {selected_type && (
          <>
            {configuration_alert && (
              <Alert inline info m={{ y: 4 }}>
                {configuration_alert}
              </Alert>
            )}
            {disable_name !== true && (
              <Div m={{ y: 4 }}>
                <resource_details_form_props.Fields
                  prefix={prefix}
                  placeholder={selected_type.label.toLowerCase()}
                  name_prefix="Destination"
                  help="Used as a unique identifier of your destination in Hookdeck."
                  name_required
                />
              </Div>
            )}
            <Div m={{ y: 4 }}>
              {required_features.map((feature) => (
                <DestinationFeature
                  feature={feature as DestinationFeatureType}
                  prefix={prefix}
                  key={feature.type}
                />
              ))}
            </Div>
            {advanced_features.length > 0 && (
              <Div m={{ y: 4, t: 6 }}>
                <Div flex={{ justify: 'flex-start', align: 'center', gap: 1 }}>
                  <ClickableArea
                    p={{ y: 0, x: 1 }}
                    m={{ l: -1 }}
                    rounded
                    style={{ width: 'auto' }}
                    flex={{ justify: 'flex-start', align: 'center', gap: 1 }}
                    onClick={() => setShowAdvanced(!show_advanced)}>
                    <Text size="s" subtitle muted={!show_advanced}>
                      Advanced Configuration
                    </Text>
                    <Icon muted icon={show_advanced ? 'expand_less' : 'expand_more'} />
                  </ClickableArea>
                  {show_advanced && <StyledHR />}
                </Div>
                {show_advanced &&
                  advanced_features.map((feature) => (
                    <DestinationFeature
                      feature={feature as DestinationFeatureType}
                      prefix={prefix}
                      key={feature.type}
                    />
                  ))}
              </Div>
            )}
          </>
        )}
      </>
    );
  },
};

export default destination_configuration_form_props;
