import { Form, Formik } from 'formik';
import { useContext } from 'react';

import { APIDestination } from '../../../../../../../../typings/Destination.interface';
import field_formats from '../../../../../utils/field-formatters';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon, { IconName } from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import DropdownMenu from '../../../../common/DropdownMenu';
import SelectInput, { StyledField } from '../../../../common/Form/Fields/SelectInput';
import SwitchInput from '../../../../common/Form/Fields/SwitchInput';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import TextInput from '../../../../common/Form/Fields/TextInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { DashboardContext } from '../../DashboardContext';
import destination_configuration_form_props from '../Forms/destination_configuration';
import resource_details_form_props from '../Forms/resource_details';
import { ResourcesContext } from '../ResourcesContext';
import useOnDelete from './hooks/useOnDeleteToggle';
import useOnDisableToggle from './hooks/useOnDisableToggle';

interface Props {
  destination: APIDestination;
}

const DestinationPreview = ({ destination }: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateResourceType } = useContext(ResourcesContext);
  const { team, destination_types } = useContext(DashboardContext);
  const { addToast } = useToasts();
  const onDisable = useOnDisableToggle('destinations');
  const onDelete = useOnDelete('destinations');

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        const post_process_values = destination_configuration_form_props.postprocessValues(
          values,
        ) as any;
        if (post_process_values.config) {
          post_process_values.config.auth_type = undefined;
          post_process_values.config.auth = undefined;
        }
        return HookdeckAPI.destinations
          .update(destination.id, {
            name: values.name,
            ...post_process_values,
          })
          .then((updated_destination: APIDestination) => {
            mutateResourceType!('destinations', updated_destination);
            resetForm({
              values: {
                name: updated_destination.name,
                ...destination_configuration_form_props.getInitialValues(updated_destination),
              },
              touched: {},
            });
            addToast('success', 'Destination updated');
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the destination${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
          });
      }}
      validate={async (values) => {
        const errors = {
          ...(await resource_details_form_props.validate(values, (name) =>
            name === destination.name
              ? Promise.resolve(false)
              : HookdeckAPI.destinations.nameIsUsed(name),
          )),
          ...(await destination_configuration_form_props.validate(values)),
        };
        return cleanseFormErrorObject(errors);
      }}
      initialValues={{
        name: destination.name,
        ...destination_configuration_form_props.getInitialValues(destination),
      }}>
      {({ dirty, resetForm, isSubmitting, isValid, values, setFieldValue }) => {
        const show_search_tooltip = (values.config.path || values.config.url)?.includes('?');

        const { rate_limit, rate_limit_period } = values.config;
        let show_rate_limit_warning = false;
        let rate_limit_per_second = Number(rate_limit);
        if (rate_limit_period === 'minute') {
          rate_limit_per_second = Number(rate_limit) / 60;
        } else if (rate_limit_period === 'hour') {
          rate_limit_per_second = Number(rate_limit) / 3600;
        }
        if (rate_limit_per_second > team!.max_events_per_second) {
          show_rate_limit_warning = true;
        }

        const destination_type = destination_types?.[values.type!];

        return (
          <Form>
            <StyledCardSection muted p={{ x: 4, y: 1 }}>
              <Text subtitle size="xs">
                {destination.disabled_at ? 'Disabled' : 'Enabled'} Destination
              </Text>
            </StyledCardSection>
            <StyledCardSection p={4}>
              <StyledField large block>
                <Text size="s" subtitle m={{ b: 1 }}>
                  Destination Type
                </Text>
                <StyledCard>
                  <StyledCardSection
                    flex={{ align: 'center', justify: 'flex-start' }}
                    h={{ px: 32 }}
                    p={{ x: 3 }}
                    style={{ lineHeight: '16px' }}>
                    <Icon icon={destination_type?.icon as IconName} left small muted />
                    <Text subtitle cursor_default>
                      {destination_type?.label}
                    </Text>
                  </StyledCardSection>
                </StyledCard>
              </StyledField>
              <Div m={{ t: 4 }}>
                <TextAreaInput
                  monospace
                  name="name"
                  label="Destination Name"
                  required
                  auto_resize={true}
                  format={field_formats.slugify}
                  maxlength={155}
                />
              </Div>
              <Text size="s" subtitle m={{ b: 1 }}>
                Destination Configuration
              </Text>
              {['HTTP', 'MOCK_API'].includes(values.type!) && (
                <>
                  {values.type && values.type === 'HTTP' && (
                    <TextAreaInput
                      name="config.url"
                      monospace
                      auto_resize={true}
                      placeholder={`https://example.com/api/webhooks`}
                      m={{ b: 2 }}
                    />
                  )}
                  <StyledCard p={{ x: 3, y: 2 }}>
                    <Div flex={{ justify: 'space-between', align: 'center' }}>
                      <Text>Max delivery rate</Text>
                      <SwitchInput show_label name={'enable_rate_limit'} />
                    </Div>
                    {values.enable_rate_limit && (
                      <Div m={{ t: 2, b: 1 }} flex={{ gap: 2 }}>
                        <TextInput
                          w={50}
                          focus
                          name="config.rate_limit"
                          placeholder="50"
                          type="number"
                          default_value={
                            team?.max_events_per_second
                              ? team?.max_events_per_second.toString()
                              : '5'
                          }
                          min={1}
                          m={0}
                        />
                        <SelectInput
                          w={50}
                          m={0}
                          block
                          name="config.rate_limit_period"
                          options={[
                            {
                              value: 'second',
                              label: 'per second',
                            },
                            {
                              value: 'minute',
                              label: 'per minute',
                            },
                            {
                              value: 'hour',
                              label: 'per hour',
                            },
                            {
                              value: 'concurrent',
                              label: 'concurrent',
                            },
                          ]}
                          required
                        />
                      </Div>
                    )}
                    {show_rate_limit_warning && (
                      <Text muted size="s" m={{ t: 1 }}>
                        This value is higher than your project throughput of{' '}
                        {team?.max_events_per_second} events per second.{' '}
                        <Link icon="arrow_forward" to="/settings/project/quotas?view=throughput">
                          Increase it
                        </Link>
                      </Text>
                    )}
                  </StyledCard>
                </>
              )}
              {values.type === 'CLI' && (
                <TextInput
                  m={0}
                  name="config.path"
                  placeholder="/webhooks"
                  help="Path to forward the request to on your localhost."
                  required
                />
              )}
              {show_search_tooltip && (
                <Text flex muted size="s" m={{ t: 2 }}>
                  <Icon icon="info" left={2} p={{ t: 0.25 }} />
                  <span>By setting query params, event query params will be ignored.</span>
                </Text>
              )}
            </StyledCardSection>
            <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'space-between' }}>
              {dirty ? (
                <>
                  <Button onClick={() => resetForm()} outline>
                    Cancel
                  </Button>
                  <Button submit primary icon="save" disabled={isSubmitting || !isValid}>
                    {isSubmitting ? <Icon icon="loading" /> : 'Save'}
                  </Button>
                </>
              ) : (
                <>
                  <Button to={`/destinations/${destination.id}`} outline icon="link">
                    Open Destination
                  </Button>
                  <DropdownMenu.Permission
                    outline
                    icon="horizontal_more"
                    placement="bottom-end"
                    options={[
                      destination.disabled_at
                        ? {
                            label: 'Enable Destination',
                            icon: 'enable',
                            onClick: () => onDisable(destination, false),
                          }
                        : {
                            label: 'Disable Destination',
                            icon: 'disable',
                            onClick: () => onDisable(destination, true),
                          },
                      {
                        label: 'Delete Destination',
                        icon: 'delete',
                        danger: true,
                        onClick: () => onDelete(destination),
                      },
                    ]}
                  />
                </>
              )}
            </StyledCardSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DestinationPreview;
