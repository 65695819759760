import { useField, useFormikContext } from 'formik';
import { useContext } from 'react';

import {
  SourceType,
  SourceTypeSchema,
} from '../../../../../../../../typings/Integration.interface';
import { capitalizeFirstLetter, fieldName } from '../../../../../utils';
import { showChat } from '../../../../../utils/liveChat';
import Icon, { IconName } from '../../../../common/base/Icon';
import Link from '../../../../common/base/Link';
import Text from '../../../../common/base/Text';
import ItemListPickerInput from '../../../../common/Form/Fields/ItemListPicker';
import { StyledField } from '../../../../common/Form/Fields/SelectInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { DashboardContext } from '../../DashboardContext';

export function SelectSourceType({
  prefix,
  onSelect,
}: {
  prefix: string;
  onSelect?: (value: SourceType) => void;
}) {
  const { submitCount } = useFormikContext();
  const { source_types } = useContext(DashboardContext);
  const [field, { error, touched }] = useField(fieldName('type', prefix));
  const has_error = (touched || submitCount > 0) && !!error;

  return (
    <StyledField large block>
      <Div flex={{ justify: 'space-between', align: 'center' }}>
        <label>
          <Text size="s" subtitle>
            Source Type
            <Text danger as="span">
              *
            </Text>
          </Text>
        </label>
      </Div>
      <ItemListPickerInput
        search_placeholder="Search for a source type..."
        name={fieldName('type', prefix)}
        onSelect={onSelect}
        outline
        categories={[
          { key: 'platform', label: 'Platform' },
          { key: 'http', label: 'Generic' },
        ]}
        getItemsFooter={(search_term, category) => {
          const category_label = category === 'http' ? 'protocols' : 'platforms';

          return (
            <Text p={{ y: 2, l: 3 }} m={0} size="s" as="p" muted>
              Looking for more {category_label}?{' '}
              <Link neutral icon={'link'} onClick={showChat}>
                Request a new source type
              </Link>
            </Text>
          );
        }}
        show_category_badge={false}
        getItemsKey={(search_team, category) => `verification-methods-${search_team}-${category}`}
        getItems={async (search_term, category) => {
          const search_results = Object.entries(source_types!).filter(([type, config]) => {
            if (config.hidden && field.value !== type) {
              return false;
            }
            if (search_term && !config.label.toLowerCase().includes(search_term.toLowerCase())) {
              return false;
            }
            if (category === 'all' && ['http', 'platform'].includes(config.category)) {
              return true;
            }

            return category === config.category;
          });

          const fallback_types_id = ['WEBHOOK', 'HTTP'];
          const fallback_types = fallback_types_id.map((id) => {
            const source_type = source_types?.[id] as SourceTypeSchema;
            return {
              ...source_type,
              label: `${source_type.label} for '${search_term}'`,
            };
          });
          const results = search_results.length
            ? search_results
            : fallback_types.map((type, index) => [fallback_types_id[index], type] as const);

          return results
            .sort(([, a], [, b]) => {
              if (a.category !== b.category) {
                return a.category.localeCompare(b.category);
              }
              if (!search_term.length && a.popular !== b.popular) {
                return a.popular ? -1 : 1;
              }
              return a.label.localeCompare(b.label);
            })
            .map(([key, config]) => ({
              id: key,
              icon: (config.icon || ('verification' as const)) as IconName,
              label: config.label,
              description: config.description,
              category: capitalizeFirstLetter(config.category),
            }));
        }}
      />
      {has_error ? (
        <Text m={{ t: 1, b: 0 }} size="s" as="p" danger>
          <Icon icon="info" left={1} />
          {error}
        </Text>
      ) : (
        <Text m={{ t: 1, b: 0 }} flex={{ align: 'center' }} size="s" as="p" muted>
          <Icon icon="info" left={1} />
          Represents the producer of your events.
        </Text>
      )}
    </StyledField>
  );
}
