import { Fragment, useContext } from 'react';
import { useTheme } from 'styled-components';

import { StyledCard } from '../../../common/base/Card';
import Container from '../../../common/base/Container';
import Divider from '../../../common/base/Divider';
import Link from '../../../common/base/Link';
import Text from '../../../common/base/Text';
import EmptyState from '../../../common/EmptyState';
import { Div } from '../../../common/helpers/StyledUtils';
import MetricCardGroup from '../../../common/metrics/MetricCard/MetricCardGroup';
import MetricChart from '../../../common/metrics/MetricChart';
import MetricTable from '../../../common/metrics/MetricTable';
import { DashboardContext } from '../DashboardContext';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../StyledView';
import ExportDropdown from './ExportDropdown';
import { metric_categories, MetricCategory } from './metric-categories';
import MetricDatePicker, { DateRange, useDateRange } from './MetricDatePicker';
import MetricsPaywall from './MetricsPaywall';

const MetricsPreview = ({
  category,
  date_range,
}: {
  category: MetricCategory;
  date_range: DateRange;
}) => {
  const { cards, tables, charts } = (category.metrics_preview || []).reduce(
    (prev, curr) => {
      if (curr.type === 'card') {
        return {
          ...prev,
          cards: [...prev.cards, curr],
        };
      }
      if (curr.type === 'table') {
        return {
          ...prev,
          tables: [...prev.tables, curr],
        };
      }
      if (curr.type.includes('chart')) {
        return {
          ...prev,
          charts: [...prev.charts, curr],
        };
      }
      return prev;
    },
    {
      cards: [],
      tables: [],
      charts: [],
    },
  );

  return (
    <Div flex={{ direction: 'column', gap: 4 }}>
      {cards?.length > 0 && (
        <MetricCardGroup
          configs={{ date_range, options: {} }}
          metrics={cards.map((m) => m.name)}
          columns={cards.length}
        />
      )}
      {tables?.length > 0 &&
        tables.map((m) => (
          <MetricTable key={m.name} metric={m.name} configs={{ date_range, options: {} }} />
        ))}
      {charts?.length > 0 &&
        charts.map((m: any) => (
          <StyledCard key={`${m.name}-${m.type}}`}>
            <MetricChart
              type={m.type}
              metric={m.name}
              configs={{
                date_range,
              }}
            />
          </StyledCard>
        ))}
    </Div>
  );
};

const MetricsView: React.FC = () => {
  const { has_connection, has_created_connections } = useContext(DashboardContext);
  const [date_range, onDateRangeChanged] = useDateRange();
  const theme = useTheme();

  return (
    <StyledViewWrapper>
      <StyledViewContent>
        <PageNav breadcrumb={[{ icon: 'metrics', title: 'Metrics' }]}>
          <MetricDatePicker date_range={date_range} onDateRangeChanged={onDateRangeChanged} />
          <ExportDropdown />
        </PageNav>
        {!has_connection ? (
          <EmptyState
            title="Track key metrics"
            description="Metrics enable you to track key trends and compare statistics across your sources, destinations, and connections. In order to experience the benefits of Hookdeck’s metrics features, create a connection."
            asset={`/images/empty/metrics-${theme.mode}.svg`}
            cta={{
              label: 'Create connection',
              icon: 'add_circle',
              to: has_created_connections ? '/connections/new' : '/create-first-connection',
            }}
          />
        ) : (
          <>
            <MetricsPaywall />
            <Container large w={100} p={{ x: 2, t: 14 }}>
              {Object.entries(metric_categories).map(([key, category], i) => (
                <Fragment key={key}>
                  {i > 0 && <Divider m={{ b: 14 }} />}
                  <Div m={{ b: 4 }} flex={{ justify: 'space-between', align: 'center' }}>
                    <Text heading size="l" as="h3" m={0}>
                      {category.label}
                    </Text>
                    <Link
                      to={(location) => ({
                        ...location,
                        pathname: `/metrics/${key}`,
                      })}>
                      View all {category.label.toLowerCase()} metrics {'->'}
                    </Link>
                  </Div>
                  <Div m={{ b: 14 }}>
                    <MetricsPreview category={category} date_range={date_range} />
                  </Div>
                </Fragment>
              ))}
            </Container>
          </>
        )}
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default MetricsView;
