import { Form, Formik } from 'formik';
import { useContext, useEffect, useState } from 'react';

import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import { HookdeckAPIError } from '../../../../../client/Hookdeck';
import field_formats from '../../../../../utils/field-formatters';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import { StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Text from '../../../../common/base/Text';
import DropdownMenu from '../../../../common/DropdownMenu';
import TextAreaInput from '../../../../common/Form/Fields/TextAreaInput';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import resource_details_form_props from '../Forms/resource_details';
import rule_form_props from '../Forms/rules';
import { ResourcesContext } from '../ResourcesContext';
import useOnDelete from './hooks/useOnDeleteToggle';
import useOnDisableToggle from './hooks/useOnDisableToggle';
import useOnPauseToggle from './hooks/useOnPauseToggle';

interface Props {
  connection: APIWebhook;
  onDirtyChange?: (isDirty: boolean) => void;
}

const ConnectionPreview = ({ connection, onDirtyChange }: Props) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { mutateResourceType } = useContext(ResourcesContext);
  const { addToast } = useToasts();
  const [error, setError] = useState<HookdeckAPIError | null>(null);
  const onPause = useOnPauseToggle();
  const onDisable = useOnDisableToggle('webhooks');
  const onDelete = useOnDelete('webhooks');

  let status_label = 'Enabled';
  if (connection.paused_at) {
    status_label = 'Paused';
  }
  if (connection.disabled_at) {
    status_label = 'Disabled';
  }

  return (
    <Formik
      onSubmit={(values, { resetForm }) => {
        return HookdeckAPI.webhooks
          .update(connection.id, {
            name: values.name || null,
            rules: values.rules ? rule_form_props.postprocessValues(values.rules) : [],
          })
          .then((updated_connection) => {
            const input_transform_rule_index = values.rules.findIndex(
              (r) => r.type === 'transform',
            );
            const output_transform_rule_index = updated_connection.rules.findIndex(
              (r) => r.type === 'transform',
            );
            if (input_transform_rule_index > -1 && output_transform_rule_index > -1) {
              updated_connection.rules[output_transform_rule_index] = {
                ...values.rules[input_transform_rule_index],
                ...updated_connection.rules[output_transform_rule_index],
              };
            }
            mutateResourceType!('webhooks', updated_connection);
            resetForm({
              values: {
                name: updated_connection.name || '',
                rules: rule_form_props.getInitialValues(updated_connection.rules),
              },
              touched: {},
            });
            addToast('success', 'Connection updated');
            setError(null);
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the connection${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
            setError(e);
          });
      }}
      validate={async (values) => {
        const errors: { name?: string; rules?: any } = {};

        const webhook_name_errors = (
          (await resource_details_form_props.validate(
            values,
            (name) => {
              return !name || connection.name === name
                ? Promise.resolve(false)
                : HookdeckAPI.webhooks.nameIsUsed(connection.source.id, name);
            },
            false,
          )) as any
        )?.name;

        if (webhook_name_errors) {
          errors.name = webhook_name_errors;
        }

        const rules_errors = await rule_form_props.validate(values.rules, HookdeckAPI);
        if (rules_errors) {
          errors.rules = rules_errors;
        }

        return cleanseFormErrorObject(errors);
      }}
      initialValues={{
        name: connection.name,
        rules: rule_form_props.getInitialValues(connection.rules),
      }}>
      {(formikProps) => (
        <ConnectionPreviewForm
          connection={connection}
          status_label={status_label}
          error={error}
          onDirtyChange={onDirtyChange}
          {...formikProps}
          onPause={onPause}
          onDisable={onDisable}
          onDelete={onDelete}
        />
      )}
    </Formik>
  );
};

interface ConnectionPreviewFormProps {
  connection: APIWebhook;
  status_label: string;
  error: HookdeckAPIError | null;
  onDirtyChange?: (isDirty: boolean) => void;
  onPause: (connection: APIWebhook, pause: boolean) => void;
  onDisable: (connection: APIWebhook, disable: boolean) => void;
  onDelete: (connection: APIWebhook) => void;
  dirty: boolean;
  resetForm: (values?: any) => void;
  isSubmitting: boolean;
  isValid: boolean;
  submitCount: number;
}

const ConnectionPreviewForm: React.FC<ConnectionPreviewFormProps> = ({
  connection,
  status_label,
  error,
  onDirtyChange,
  dirty,
  resetForm,
  isSubmitting,
  isValid,
  submitCount,
  onPause,
  onDisable,
  onDelete,
}) => {
  useEffect(() => {
    if (onDirtyChange) {
      onDirtyChange(dirty);
    }
  }, [dirty, onDirtyChange]);

  return (
    <Form>
      <StyledCardSection muted p={{ x: 4, y: 1 }}>
        <Text subtitle size="xs">
          {status_label} Connection
        </Text>
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }} max_h={{ vh: 60 }} scroll>
        <TextAreaInput
          monospace
          name="name"
          label="Connection Name"
          auto_resize={true}
          placeholder="Enter a connection name or leave empty"
          format={field_formats.slugify}
          maxlength={155}
        />
        <Text subtitle size="s" m={{ b: 1, t: 4 }}>
          Connection Rules
        </Text>
        <rule_form_props.Fields
          prefix="rules"
          webhook_id={connection.id}
          source_id={connection.source.id}
          display="compact"
        />
        {error && (
          <Text danger m={{ t: 2 }}>
            {typeof error.response === 'string'
              ? error.response
              : error.response?.data?.[0] || 'Fatal error'}
          </Text>
        )}
      </StyledCardSection>
      <StyledCardSection
        p={{ x: 4, y: 3 }}
        flex={{ justify: dirty ? 'space-between' : 'space-between' }}>
        {dirty ? (
          <>
            <Button onClick={() => resetForm()} outline>
              Cancel
            </Button>
            <Button.Permission
              submit
              primary
              icon="save"
              disabled={isSubmitting || (submitCount > 0 && !isValid)}>
              {isSubmitting ? <Icon icon="loading" /> : 'Save'}
            </Button.Permission>
          </>
        ) : (
          <>
            <Button to={`/connections/${connection.id}`} outline icon="link">
              Open Connection
            </Button>

            <Div flex={{ gap: 2 }}>
              <Button.Permission
                outline
                icon={connection.paused_at ? 'play_circle' : 'pause_circle'}
                onClick={() =>
                  connection.paused_at ? onPause(connection, false) : onPause(connection, true)
                }>
                {connection.paused_at ? 'Unpause' : 'Pause'}
              </Button.Permission>
              <DropdownMenu.Permission
                outline
                icon="horizontal_more"
                placement="bottom-end"
                options={[
                  connection.disabled_at
                    ? {
                        label: 'Enable Connection',
                        icon: 'enable',
                        onClick: () => onDisable(connection, false),
                      }
                    : {
                        label: 'Disable Connection',
                        icon: 'disable',
                        onClick: () => onDisable(connection, true),
                      },
                  {
                    label: 'Delete Connection',
                    icon: 'delete',
                    danger: true,
                    onClick: () => onDelete(connection),
                  },
                ]}
              />
            </Div>
          </>
        )}
      </StyledCardSection>
    </Form>
  );
};

export default ConnectionPreview;
