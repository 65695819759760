import { EventStatus } from '../../../../../../../../typings/Event.interface';
import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import Dropdown from '../../../../common/Dropdown';
import PreviewHistogram from '../../../../common/Histogram/PreviewHistogram';
import Button, { ClickableArea } from '../../../../common/base/Button';
import { StyledCardSection } from '../../../../common/base/Card';
import Text from '../../../../common/base/Text';
import { Div } from '../../../../common/helpers/StyledUtils';

type Props = {
  data_2h_sample: [[Date, Date], Record<EventStatus, number>][];
  data_4h_sample: [[Date, Date], Record<EventStatus, number>][];
  connection: APIWebhook;
};

const dimentions_config = {
  SUCCESSFUL: {
    color: 'success',
    label: 'Successful',
  },
  FAILED: {
    color: 'danger',
    label: 'Failed',
  },
  HOLD: { color: 'warning', label: 'Paused' },
  QUEUED: { color: 'primary', label: 'Queued' },
  SCHEDULED: {
    color: 'grayscale',
    label: 'Scheduled',
  },
} as const;

const MetricsPreview = ({ data_2h_sample, data_4h_sample, connection }: Props) => {
  return (
    <Dropdown
      placement="bottom-end"
      renderToggle={(opened, toggle) => (
        <ClickableArea on="background" rounded p={1.5} onClick={() => toggle(!opened)}>
          <PreviewHistogram
            size="small"
            values={data_4h_sample}
            dimentions_config={dimentions_config}
            resolution="hour"
          />
        </ClickableArea>
      )}>
      <StyledCardSection p={4} w={{ px: 382 }}>
        <Div flex m={{ b: 2 }}>
          <Text size="s" subtitle>
            Events
          </Text>
          <Text size="s" subtitle muted m={{ x: 1 }}>
            {' · '}
          </Text>
          <Text size="s" subtitle muted>
            Last 24h
          </Text>
        </Div>
        <PreviewHistogram
          values={data_2h_sample}
          resolution="hour"
          dimentions_config={dimentions_config}
          show_tooltip
          size="medium"
        />
      </StyledCardSection>
      <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'flex-end' }}>
        <Button outline to={`/events?webhook_id[0]=${connection.id}`} icon="link">
          View All Events
        </Button>
      </StyledCardSection>
    </Dropdown>
  );
};

export default MetricsPreview;
