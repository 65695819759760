import { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import useSWR from 'swr';
import { APIWebhook } from '../../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import Button from '../../../common/base/Button';
import Icon from '../../../common/base/Icon';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import CopyableField from '../../../common/CopyableField';
import { Div } from '../../../common/helpers/StyledUtils';
import { useToasts } from '../../../common/Toast';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { APIList } from '../../../../../../../typings/API.interface';

const StyledEmptyView = styled.div`
  flex-grow: 1;
  max-width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EventListEmptyView: React.FC<{ webhooks: APIList<APIWebhook> }> = ({ webhooks }) => {
  const default_webhook = webhooks?.count > 0 && webhooks.models[0];
  const { HookdeckAPI } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { addToast } = useToasts();
  const { data: events } = useSWR(APIMethodKeys.events.list({ limit: 1 }), () =>
    HookdeckAPI.events.list({ limit: 1 }),
  );
  const { data: cli_events } = useSWR(
    APIMethodKeys.events.list({ limit: 1, cli_id: { any: true } }),
    () => HookdeckAPI.events.list({ limit: 1, cli_id: { any: true } }),
  );

  const onSendTestEvent = () => {
    setLoading(true);
    if (default_webhook) {
      HookdeckAPI.webhooks
        .test(default_webhook.id)
        .then(() => {
          addToast('info', "Test event sent, hold on a second while it's processed!");
          setLoading(true);
        })
        .catch(() => setLoading(false));
    }
  };

  if (!events || !cli_events || !webhooks) {
    return (
      <StyledEmptyView>
        <Loading />
      </StyledEmptyView>
    );
  }

  if (default_webhook && cli_events.count === 0 && location.pathname === '/events/cli') {
    return (
      <StyledEmptyView>
        <Div p={{ all: 7 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
          <Text heading center as="p" size="xl" m={{ t: 4 }}>
            Receive events on your local server
          </Text>
          <Text as="p" center>
            Install the CLI to receive your events on your local server while developing or
            debugging your integration.{' '}
            {events.count === 0 && 'Once connected send a request to your Hookdeck URL.'}
          </Text>
          {events.count === 0 && <CopyableField value={default_webhook.source.url} />}
          <Button as="a" m={{ y: 6 }} target="_blank" href={LINKS.product_docs.cli}>
            Install
          </Button>
        </Div>
      </StyledEmptyView>
    );
  }

  if (default_webhook && events.count === 0 && cli_events.count === 0) {
    return (
      <StyledEmptyView>
        <Div p={{ all: 7 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
          <Icon icon="loading" muted />
          <Text heading as="p" size="xl" m={{ t: 4 }}>
            Waiting for an event
          </Text>
          <Text center as="p">
            Update your source <strong>{default_webhook.source.name}</strong> with the provided
            Hookdeck URL.
          </Text>
          <CopyableField monospace value={default_webhook.source.url} />
          <Text size="s" muted m={{ y: 4 }}>
            – or –
          </Text>
          <Button minimal primary disabled={loading} onClick={onSendTestEvent}>
            Send a test request
          </Button>
        </Div>
      </StyledEmptyView>
    );
  }

  return (
    <StyledEmptyView>
      <Div p={{ y: 24 }} flex={{ direction: 'column', align: 'center', justify: 'center' }}>
        <Text as="p" subtitle muted m={0}>
          No events match those filters...
        </Text>
      </Div>
    </StyledEmptyView>
  );
};

export default EventListEmptyView;
