import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';

import { APIDestination } from '../../../../../../../../typings/Destination.interface';
import { cleanseFormErrorObject } from '../../../../../utils/form';
import Button from '../../../../common/base/Button';
import { Div } from '../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import configuration_form_props, {
  DestinationConfigurationFormValues,
} from '../../Connections/Forms/destination_configuration';
import resource_details_form_props from '../../Connections/Forms/resource_details';
import { useToasts } from '../../../../common/Toast';
import ApiModal from '../ApiModal';

const AddDestination: React.FC<{
  context: { destination: APIDestination | null };
  mutateContext: (any) => void;
  nextStep: () => void;
}> = ({ context: { destination }, mutateContext, nextStep }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { addToast } = useToasts();
  const [show_api_modal, showAPIModal] = useState(false);

  return (
    <Formik
      key={destination?.id}
      initialValues={{
        ...resource_details_form_props.getInitialValues(destination || undefined),
        ...configuration_form_props.getInitialValues(destination || undefined),
      }}
      validateOnMount
      validate={async (values) =>
        cleanseFormErrorObject({
          ...(await resource_details_form_props.validate(
            values,
            (name) =>
              destination && name === destination.name
                ? Promise.resolve(false)
                : HookdeckAPI.destinations.nameIsUsed(name),
            true,
          )),
          ...(await configuration_form_props.validate(
            values as DestinationConfigurationFormValues,
          )),
        })
      }
      onSubmit={(v, { resetForm }) => {
        const values = {
          ...resource_details_form_props.postprocessValues(v),
          ...configuration_form_props.postprocessValues(v as never),
        };
        const promise = destination
          ? HookdeckAPI.destinations.update(destination.id, values)
          : HookdeckAPI.destinations.create(values);
        return promise
          .then((new_destination) => {
            if (destination) {
              addToast('success', `Destination ${new_destination.name} configuration updated`);
            }
            resetForm({
              values: {
                ...resource_details_form_props.getInitialValues(new_destination || undefined),
                ...configuration_form_props.getInitialValues(new_destination || undefined),
                show_advanced: v.show_advanced || false,
              },
            });
            mutateContext({ destination: new_destination });
            nextStep();
          })
          .catch((e) => {
            addToast(
              'error',
              `An error occurred while saving the destination${
                e.response?.data[0] ? `: ${e.response.data[0]}` : ''
              }`,
            );
            return;
          });
      }}>
      {({ isValid, isSubmitting, handleSubmit, dirty, values }) => (
        <Form onSubmit={handleSubmit}>
          <Div m={{ t: 0 }}>
            <configuration_form_props.Fields prefix="" />
          </Div>
          <Div flex={{ gap: 3 }} m={{ t: 8 }}>
            <Button
              submit
              disabled={!isValid || isSubmitting || (!!destination && !dirty)}
              primary={!destination}
              outline={!!destination}
              icon={isSubmitting ? 'loading' : destination ? 'save' : 'add_circle'}>
              {destination ? 'Update Destination' : 'Create Destination'}
            </Button>
            <Button minimal icon="code" onClick={() => showAPIModal(true)} disabled={!isValid}>
              Use API
            </Button>
            {show_api_modal && (
              <ApiModal
                action="create-destination"
                onClose={() => showAPIModal(false)}
                values={{
                  ...resource_details_form_props.postprocessValues(values),
                  ...configuration_form_props.postprocessValues(values as never),
                }}
              />
            )}
          </Div>
        </Form>
      )}
    </Formik>
  );
};

export default AddDestination;
