import { Form, Formik, FormikProps } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../client/APIMethodKeys';
import { numberWithCommas } from '../../../../../utils';
import { useCopyToClipboard } from '../../../../../utils/copy';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard } from '../../../../common/base/Card';
import Container from '../../../../common/base/Container';
import Divider from '../../../../common/base/Divider';
import Loading from '../../../../common/base/Loading';
import Skeleton from '../../../../common/base/Skeleton';
import Text from '../../../../common/base/Text';
import { useDialog } from '../../../../common/Dialog';
import { Div } from '../../../../common/helpers/StyledUtils';
import { useMetric } from '../../../../common/metrics/useMetric';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { useDateRange } from '../../Metrics/MetricDatePicker';
import NotFound from '../../NotFound';
import { PageNav, StyledViewContent, StyledViewWrapper } from '../../StyledView';
import ConfirmDeleteDialog from '../ConfirmDeleteDialog';
import resource_details_form_props from '../Forms/resource_details';
import configuration_form_props, {
  SourceConfigurationFormValues,
} from '../Forms/source_configuration';
import ResourceMetricSection from '../ResourceMetricsSection';
import SaveButton from '../SaveButton';
import { TeamPermission } from '../../../../contexts/TeamPermissionContext';
import { DashboardContext } from '../../DashboardContext';
import { IconName } from '../../../../common/base/Icon';
import { SourceType } from '../../../../../../../../typings/Integration.interface';

const SourceView = () => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const formRef = useRef<FormikProps<SourceConfigurationFormValues>>(null);
  const { source_types } = useContext(DashboardContext);
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();
  const { addToast } = useToasts();
  const showDialog = useDialog();
  const history = useHistory();
  const [type, setType] = useState<SourceType | null>(null);
  const {
    data: source,
    mutate,
    error,
  } = useSWR(APIMethodKeys.sources.get(id, { include: 'config.auth' }), () =>
    HookdeckAPI.sources.get(id, { include: 'config.auth' }),
  );

  useEffect(() => {
    const new_type = source?.type || formRef?.current?.values?.type;
    setType(new_type || null);
  }, [source?.type, formRef?.current?.values?.type]);

  const source_configuration_form_props = source ? configuration_form_props : undefined;

  const [date_range] = useDateRange();

  const { data: requests_count } = useMetric<number>('card', 'requests_count', 0, {
    options: {
      filters: { source_id: [id] },
    },
    date_range,
  });

  const { data: connections_count } = useSWR(APIMethodKeys.webhooks.count({ source_id: id }), () =>
    HookdeckAPI.webhooks.count({ source_id: id }),
  );

  const copyToClipboard = useCopyToClipboard();

  const onUpdate = async (values) =>
    HookdeckAPI.sources
      .update(id, values)
      .then((source_updated) => {
        const source_merged = { ...source, ...source_updated };
        mutate(source_merged);
        addToast('success', 'Source saved');
        return source_merged;
      })
      .catch((e) => {
        addToast(
          'error',
          `An error occurred while saving the source${
            e.response?.data[0] ? `: ${e.response.data[0]}` : ''
          }`,
        );
        throw e;
      });

  const onEnable = async () => {
    HookdeckAPI.sources
      .enable(id)
      .then((source) => {
        mutate(source);
        addToast('success', 'Source enabled');
      })
      .catch(() => {
        addToast('error', 'Failed to enable source');
      });
  };

  const onDisable = async () => {
    showDialog(
      () => {
        HookdeckAPI.sources
          .disable(id)
          .then((source) => {
            mutate(source);
            addToast('success', 'Source disabled');
          })
          .catch(() => {
            addToast('error', 'Failed to disable source');
          });
      },
      () => null,
      {
        title: 'Disable Source',
        submit_label: 'Disable',
        cancel_label: 'Cancel',
        message:
          'By disabling this source, all connections associated with this source will also be disabled. Are you sure?',
      },
    );
  };

  const onDelete = async () => {
    showDialog(
      () => {
        HookdeckAPI.sources
          .delete(id)
          .then(() => {
            addToast('success', 'Source deleted');
            history.push('/connections');
          })
          .catch(() => {
            addToast('error', 'Failed to delete source');
          });
      },
      () => null,
      {
        danger: true,
        title: 'Delete Source',
        submit_label: 'Delete',
        cancel_label: 'Keep Source',
        message: ConfirmDeleteDialog({
          text: 'By deleting this source, no requests will be accepted or delivered anymore. All connections associated with this source will also be deleted. Are you sure?',
        }),
      },
    );
  };

  if (error && error.response?.status === 404) {
    return (
      <NotFound
        title="Source not found"
        description="This source does not exist or has been deleted."
        id={id}
      />
    );
  }

  if (error && error.response?.status === 410) {
    return (
      <StyledViewWrapper>
        <StyledViewContent>
          <Div flex={{ justify: 'center' }} p={8}>
            <Container small center>
              <Text subtitle center size="l">
                Source deleted
              </Text>
              <Text as="p" m={{ t: 4, b: 2 }}>
                This source was permanently deleted.
              </Text>
              <Button primary minimal m={{ t: 4 }} as={RouterLink} to={`/connections`}>
                Connections
              </Button>
            </Container>
          </Div>
        </StyledViewContent>
      </StyledViewWrapper>
    );
  }

  return (
    <StyledViewWrapper>
      <StyledViewContent light>
        <PageNav
          breadcrumb={[
            { icon: 'connections', title: 'Connections', path: '/connections' },
            { icon: 'source', title: source?.name || null, monospace: true },
          ]}
        />
        <Container large>
          {!source || !source_configuration_form_props ? (
            <Div m={{ t: 8 }}>
              <Loading />
            </Div>
          ) : (
            <Div m={{ b: 14 }}>
              <Text as="h2" heading m={{ t: 14, b: 2 }} flex={{ align: 'center' }}>
                Source Overview
              </Text>
              <Divider />
              <Div flex={{ gap: 4 }} m={{ t: 4 }}>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Source Type
                    </Text>
                    <Badge muted icon={source_types![source.type].icon as IconName} small>
                      {source_types![source.type].label}
                    </Badge>
                  </Div>
                </StyledCard>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Source Status
                    </Text>
                    <Badge
                      muted={!!source?.disabled_at}
                      subtle={!source?.disabled_at}
                      success={!source?.disabled_at}
                      icon={!source?.disabled_at ? 'bolt' : 'disable'}
                      small>
                      {!source?.disabled_at ? 'Active' : 'Disabled'}
                    </Badge>
                  </Div>
                </StyledCard>
              </Div>
              <Div flex={{ gap: 4 }} m={{ t: 4 }}>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Connections
                    </Text>
                    {connections_count !== undefined ? (
                      <Text monospace ellipsis>
                        {connections_count.count}
                      </Text>
                    ) : (
                      <Skeleton w={{ px: 100 }} h={{ px: 20 }} loading />
                    )}
                  </Div>
                  <Button outline to={`/connections?source_id=${id}`} icon="link" />
                </StyledCard>
                <StyledCard flex={{ justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Requests · Last 24h
                    </Text>
                    {requests_count !== undefined ? (
                      <Text monospace ellipsis>
                        {numberWithCommas(requests_count)}
                      </Text>
                    ) : (
                      <Skeleton w={{ px: 100 }} h={{ px: 20 }} loading />
                    )}
                  </Div>
                  <Button outline to={`/requests?source_id[0]=${id}`} icon="link" />
                </StyledCard>
              </Div>
              <Div flex={{ gap: 4 }} m={{ t: 4 }}>
                <StyledCard flex={{ grow: true, justify: 'space-between', align: 'center' }} p={3}>
                  <Div>
                    <Text subtitle muted size="s">
                      Source ID
                    </Text>
                    <Text monospace ellipsis>
                      {source.id}
                    </Text>
                  </Div>
                  <Button outline onClick={() => copyToClipboard(source.id)} icon="copy" />
                </StyledCard>
                <StyledCard
                  overflow_hidden
                  flex={{ grow: true, justify: 'space-between', align: 'center' }}
                  p={3}>
                  <Div w={100} style={{ overflow: 'hidden' }}>
                    <Text subtitle muted size="s">
                      Source URL
                    </Text>
                    <Text monospace ellipsis>
                      {source.url}
                    </Text>
                  </Div>
                  <Button
                    m={{ l: 4 }}
                    outline
                    onClick={() => copyToClipboard(source.url)}
                    icon="copy"
                  />
                </StyledCard>
              </Div>
              <ResourceMetricSection id={id} resource="source" />
              <TeamPermission role="member">
                <Formik
                  key={source.id}
                  initialValues={resource_details_form_props.getInitialValues(source)}
                  validate={(values) =>
                    resource_details_form_props.validate(values, (name) =>
                      name === source.name
                        ? Promise.resolve(false)
                        : HookdeckAPI.sources.nameIsUsed(name),
                    )
                  }
                  onSubmit={(values, { resetForm }) =>
                    onUpdate(resource_details_form_props.postprocessValues(values)).then(
                      (source) => {
                        resetForm({
                          values: resource_details_form_props.getInitialValues(source),
                        });
                      },
                    )
                  }>
                  <Form>
                    <Text as="h2" heading m={{ t: 14, b: 2 }}>
                      Source Details
                    </Text>
                    <Divider m={{ b: 4 }} />
                    <resource_details_form_props.Fields
                      prefix=""
                      placeholder="shopify-prod"
                      show_description
                    />
                    <SaveButton m={{ t: 4 }} />
                  </Form>
                </Formik>
                <Text as="h2" heading m={{ t: 14, b: 2 }}>
                  Configuration
                </Text>
                <Divider m={{ b: 2 }} />
                <Formik
                  key={`${source.id}-${type}`}
                  innerRef={formRef}
                  initialValues={source_configuration_form_props.getInitialValues({
                    ...source,
                    type: source.type || type || undefined,
                  })}
                  validate={(v) =>
                    source_configuration_form_props.validate(
                      v as SourceConfigurationFormValues,
                      source_types!,
                    )
                  }
                  onSubmit={async (values, { resetForm }) => {
                    return onUpdate(
                      source_configuration_form_props.postprocessValues(
                        values as SourceConfigurationFormValues,
                      ),
                    ).then(() => resetForm({ values }));
                  }}>
                  <Form>
                    <source_configuration_form_props.Fields prefix="" disable_name show_all />
                    <SaveButton m={{ t: 2 }} />
                  </Form>
                </Formik>
                <Text as="h2" heading m={{ t: 14, b: 0 }}>
                  {source.disabled_at ? 'Enable' : 'Disable'} Source
                </Text>
                <Text as="p" muted m={{ b: 2 }}>
                  {source.disabled_at
                    ? 'Enabling your source will allow new incoming requests for your Source URL.'
                    : 'Disabling your source will reject any new incoming requests for your Source URL.'}
                </Text>
                <Divider m={{ b: 4 }} />
                <Button.Permission
                  outline
                  onClick={source.disabled_at ? onEnable : onDisable}
                  icon={source.disabled_at ? 'add_circle' : 'inventory'}>
                  {source.disabled_at ? 'Enable' : 'Disable'}
                </Button.Permission>
                <Text as="h2" heading m={{ t: 14, b: 0 }}>
                  Delete Source
                </Text>
                <Text as="p" muted m={{ b: 2 }}>
                  Deleting this source will permanently delete it and all associated connections.
                  Requests to your source URL will return a HTTP 410 and won't be logged.
                </Text>
                <Divider m={{ b: 4 }} />
                <Button.Permission danger onClick={onDelete} icon="delete">
                  Delete
                </Button.Permission>
              </TeamPermission>
            </Div>
          )}
        </Container>
      </StyledViewContent>
    </StyledViewWrapper>
  );
};

export default SourceView;
