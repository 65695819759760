import { useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

import { Event } from '../../../../../../../typings/Event.interface';
import { Request } from '../../../../../../../typings/Request.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { useCopyToClipboard } from '../../../../utils/copy';
import {
  getRejectionCauseBreakdown,
  rejection_cause_labels,
} from '../../../../utils/rejection-causes';
import Badge from '../../../common/base/Badge';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Icon from '../../../common/base/Icon';
import LabelButton from '../../../common/base/LabelButton';
import Link from '../../../common/base/Link';
import Loading from '../../../common/base/Loading';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useCopyASCurl from '../../../hooks/useCopyAsCurl';
import { DashboardContext } from '../DashboardContext';
import {
  StyledViewScrollable,
  StyledViewSidebar,
  StyledViewSidebarFooter,
  StyledViewSidebarNav,
  StyledViewSidebarSection,
} from '../StyledView';
import Skeleton from '../../../common/base/Skeleton';
import DisplayDate from '../../../common/DisplayDate';
import { numberWithCommas } from '../../../../utils';
import FullRequestData from '../../../common/Request/FullRequestData';

const RequestSidebar: React.FC<{
  request_id: string;
  list_request?: Request;
  onClose: () => void;
  retry: (
    request_id: string,
    webhook_ids?: string[],
  ) => Promise<{ request: Request; events: Event[] }>;
}> = ({ request_id, list_request, retry, onClose }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription, organization } = useContext(DashboardContext);
  const [expanded, setExpanded] = useState(false);
  const { data: request, mutate } = useSWR(APIMethodKeys.requests.get(request_id), () =>
    HookdeckAPI.requests.get(request_id),
  );

  useEffect(() => {
    if (list_request && request && list_request.id === request.id) {
      mutate(
        {
          ...request,
          ...list_request,
        },
        false,
      );
    }
  }, [list_request]);

  const copyToClipboard = useCopyToClipboard();

  const { data: source } = useSWR(request && APIMethodKeys.sources.get(request.source_id), () =>
    HookdeckAPI.sources.get(request!.source_id),
  );

  const copyAsCurl = useCopyASCurl({
    type: 'request',
    id: request_id,
    event_data: request?.data ?? undefined,
  });

  const rejection_cause_breakdown =
    request?.rejection_cause &&
    source &&
    getRejectionCauseBreakdown(request.rejection_cause)({
      request,
      source,
    });

  return (
    <StyledViewSidebar>
      <StyledViewSidebarNav background_color="surface">
        <Text subtitle text_wrap={false}>
          Request
        </Text>
        <Div flex={{ align: 'center', gap: 2 }}>
          {request?.rejection_cause && (
            <Button.Permission
              role="member"
              minimal
              icon="retry"
              onClick={() => retry(request_id).then(({ request }) => mutate(request))}>
              Retry
            </Button.Permission>
          )}
          <Tooltip tooltip="Copy request as cURL" placement="bottom-end">
            <Button minimal icon="copy" onClick={copyAsCurl}>
              cURL
            </Button>
          </Tooltip>
          <Button minimal icon="link" to={`/requests/${request_id}`} />
          <Button minimal icon="close" onClick={onClose} />
        </Div>
      </StyledViewSidebarNav>
      <StyledViewScrollable>
        <Loading
          require={[request, source]}
          wrapper={() => (
            <StyledViewSidebarSection>
              <StyledCard m={{ b: 4 }}>
                <StyledCardSection p={{ x: 4, y: 3 }}>
                  <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
                  <Skeleton h={{ px: 20 }} w={{ px: 58 }} loading />
                </StyledCardSection>
                <StyledCardSection p={{ x: 4, y: 3 }}>
                  <Skeleton h={{ px: 20 }} w={{ px: 40 }} loading m={{ b: 1 }} />
                  <Skeleton h={{ px: 20 }} w={{ px: 128 }} loading />
                </StyledCardSection>
              </StyledCard>
              <Skeleton h={{ px: 200 }} w={100} loading m={{ b: 4 }} />
              <Skeleton h={{ px: 200 }} w={100} loading />
            </StyledViewSidebarSection>
          )}>
          {() => (
            <>
              {!request!.data ? (
                <StyledViewSidebarSection>
                  <Text as="p">
                    This request is past your archival period of {subscription!.retention_days}{' '}
                    days, the data is no longer available.
                  </Text>
                  {!organization && (
                    <Link to="/settings/organization/plans?highlight=retention_days" icon="upgrade">
                      Upgrade Plan
                    </Link>
                  )}
                </StyledViewSidebarSection>
              ) : (
                <StyledViewSidebarSection>
                  <StyledCard m={{ b: 4 }}>
                    <StyledCardSection p={{ x: 4, y: 3 }}>
                      <Text size="s" subtitle m={{ b: 1 }}>
                        Status
                      </Text>
                      {rejection_cause_breakdown ? (
                        <Badge small danger subtle>
                          {rejection_cause_labels[request.rejection_cause]}
                        </Badge>
                      ) : (
                        <Badge small success subtle>
                          Accepted
                        </Badge>
                      )}
                    </StyledCardSection>
                    {rejection_cause_breakdown && (
                      <StyledCardSection p={{ x: 4, y: 3 }}>
                        <Text size="s" subtitle m={{ b: 1 }}>
                          Description
                        </Text>
                        <Text size="s">{rejection_cause_breakdown.text}</Text>
                      </StyledCardSection>
                    )}
                    <StyledCardSection p={{ x: 4, y: 3 }}>
                      <Text size="s" subtitle m={{ b: 1 }}>
                        Source
                      </Text>
                      <LabelButton
                        neutral
                        small
                        monospace
                        to={source && `/sources/${source.id}`}
                        label={source?.name || ''}
                      />
                    </StyledCardSection>
                    {expanded && (
                      <>
                        <StyledCardSection p={{ x: 4, y: 3 }}>
                          <Text size="s" subtitle m={{ b: 1 }}>
                            Ingested At
                          </Text>
                          <DisplayDate date={request!.ingested_at} />
                        </StyledCardSection>
                        <StyledCardSection p={{ x: 4, y: 3 }}>
                          <Text size="s" subtitle m={{ b: 1 }}>
                            Processed At
                          </Text>
                          <Div flex={{ justify: 'space-between' }}>
                            <DisplayDate date={request!.created_at} />
                            <Text as="span" text_wrap={false} size="s" subtitle muted>
                              {numberWithCommas(
                                new Date(request!.created_at).getTime() -
                                  new Date(request!.ingested_at).getTime(),
                              )}
                              ms
                            </Text>
                          </Div>
                        </StyledCardSection>
                        <StyledCardSection p={{ x: 4, y: 3 }}>
                          <Text size="s" subtitle m={{ b: 1 }}>
                            Created Events
                          </Text>
                          <Badge small icon="success" muted>
                            {request!.events_count}
                          </Badge>
                        </StyledCardSection>
                        <StyledCardSection p={{ x: 4, y: 3 }}>
                          <Text size="s" subtitle m={{ b: 1 }}>
                            Ignored Events
                          </Text>
                          <Badge small icon="block" muted>
                            {request!.ignored_count}
                          </Badge>
                        </StyledCardSection>
                      </>
                    )}
                    <StyledCardSection p={{ x: 4, y: 3 }}>
                      <Link
                        as="button"
                        small
                        icon={expanded ? 'collapse_all' : 'expand_all'}
                        flex={{ align: 'center' }}
                        onClick={() => setExpanded(!expanded)}>
                        {!expanded ? `View Metadata` : 'Hide Metadata'}
                      </Link>
                    </StyledCardSection>
                  </StyledCard>
                  <FullRequestData data={request!.data} compact type="request" id={request!.id} />
                </StyledViewSidebarSection>
              )}
            </>
          )}
        </Loading>
      </StyledViewScrollable>
      <StyledViewSidebarFooter>
        <ClickableArea
          rounded
          block={false}
          p={{ x: 2, y: 1 }}
          flex={{ align: 'center' }}
          onClick={() => copyToClipboard(request_id)}>
          <Text muted monospace>
            {request_id}
          </Text>
          <Icon right icon="copy" muted />
        </ClickableArea>
      </StyledViewSidebarFooter>
    </StyledViewSidebar>
  );
};

export default RequestSidebar;
