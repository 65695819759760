import { Form, Formik } from 'formik';
import { useContext } from 'react';
import {
  APIDestination,
  DestinationRateLimitPeriod,
} from '../../../../../../../typings/Destination.interface';
import { Subscription } from '../../../../../../../typings/Subscription.interface';
import { isFreePlan } from '../../../../utils/subscription';
import SelectInput from '../../../common/Form/Fields/SelectInput';
import TextInput from '../../../common/Form/Fields/TextInput';
import FormError from '../../../common/Form/FormError';
import { useToasts } from '../../../common/Toast';
import Button from '../../../common/base/Button';
import { StyledCardSection } from '../../../common/base/Card';
import Link from '../../../common/base/Link';
import { Div } from '../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { DashboardContext } from '../DashboardContext';
import Text from '../../../common/base/Text';

const ChangeRateLimit = ({
  subscription,
  destination,
  onUpdate,
}: {
  subscription: Subscription;
  destination: APIDestination;
  onUpdate?: (destination: APIDestination) => void;
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team } = useContext(DashboardContext);
  const { addToast } = useToasts();

  const onRateLimitUpdated = (
    rate_limit: number,
    rate_limit_period: DestinationRateLimitPeriod,
  ) => {
    HookdeckAPI.destinations
      .update(destination.id, { rate_limit, rate_limit_period })
      .then((destination) => {
        addToast('success', 'Max delivery rate updated');
        onUpdate?.(destination);
      })
      .catch(() => {
        addToast('error', 'Failed to update max delivery rate');
      });
  };

  if (!('rate_limit' in destination.config && 'rate_limit_period' in destination.config)) {
    throw new Error('Invalid destination type');
  }

  return (
    <Formik
      onSubmit={(values) => {
        values.rate_limit && onRateLimitUpdated(values.rate_limit, values.rate_limit_period);
      }}
      validateOnBlur={false}
      initialValues={{
        rate_limit: destination.config.rate_limit,
        rate_limit_period: destination.config.rate_limit_period || 'second',
      }}>
      {(props) => {
        const { rate_limit, rate_limit_period } = props.values;
        let show_rate_limit_warning = false;
        let rate_limit_per_second = Number(rate_limit);
        if (rate_limit_period === 'minute') {
          rate_limit_per_second = Number(rate_limit) / 60;
        } else if (rate_limit_period === 'hour') {
          rate_limit_per_second = Number(rate_limit) / 3600;
        }
        if (rate_limit_per_second > team!.max_events_per_second) {
          show_rate_limit_warning = true;
        }

        return (
          <Form>
            <StyledCardSection p={3} w={{ px: 248 }}>
              <Text subtitle size="s" m={{ b: 1 }}>
                Max delivery rate
              </Text>
              <Div flex={{ gap: 2 }}>
                <TextInput
                  m={0}
                  w={{ px: 120 }}
                  name={'rate_limit'}
                  placeholder="50"
                  type="number"
                  min={0}
                  show_error={false}
                />
                <SelectInput
                  m={0}
                  name={'rate_limit_period'}
                  options={[
                    {
                      value: 'second',
                      label: 'per second',
                    },
                    {
                      value: 'minute',
                      label: 'per minute',
                    },
                    {
                      value: 'hour',
                      label: 'per hour',
                    },
                    {
                      value: 'concurrent',
                      label: 'concurrent',
                    },
                  ]}
                  required
                />
              </Div>
              <Div m={{ t: 2 }}>
                <FormError field="rate_limit" error_limit={1}>
                  {(field) =>
                    field === 'rate_limit' &&
                    isFreePlan(subscription.plan) && (
                      <Link to="/settings/organization/plans">
                        You can upgrade to increase limit.
                      </Link>
                    )
                  }
                </FormError>
              </Div>
              {show_rate_limit_warning && (
                <Text muted size="s" m={{ t: 1 }}>
                  This value is higher than your project throughput of {team?.max_events_per_second}{' '}
                  events per second.{' '}
                  <Link icon="arrow_forward" to="/settings/project/quotas?view=throughput">
                    Increase it
                  </Link>
                </Text>
              )}
            </StyledCardSection>
            <StyledCardSection p={3} flex={{ justify: 'flex-end' }}>
              <Button submit primary>
                Update
              </Button>
            </StyledCardSection>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeRateLimit;
