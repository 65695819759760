import { useContext } from 'react';

import { APIDestination } from '../../../../../../../typings/Destination.interface';
import { ClickableArea, ClickableAreaProps } from '../../../common/base/Button';
import { StyledCard } from '../../../common/base/Card';
import Icon, { IconName } from '../../../common/base/Icon';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import { Div } from '../../../common/helpers/StyledUtils';
import { Truncatable } from '../../../common/Truncatable';
import { DashboardContext } from '../DashboardContext';

interface Props extends Pick<ClickableAreaProps, 'to' | 'onClick'> {
  destination: APIDestination;
}

const DestinationCard: React.FC<Props> = ({ destination, onClick, to }) => {
  const { destination_types } = useContext(DashboardContext);
  const destination_type = destination_types?.[destination?.type];

  return (
    <StyledCard overflow_hidden>
      <ClickableArea
        p={{ x: 2, y: 1.5 }}
        flex={{ justify: 'space-between', align: 'center' }}
        onClick={onClick}
        to={to}>
        <Div flex={{ align: 'center', justify: 'space-between' }} w={100}>
          <Div flex={{ align: 'center' }} w={100}>
            {destination_type?.icon && (
              <Icon icon={destination_type.icon as IconName} muted left pointer />
            )}
            <Text w={100} ellipsis monospace muted={!!destination.disabled_at}>
              <Truncatable text={destination.name} />
            </Text>
          </Div>
          {destination.disabled_at && (
            <Tooltip tooltip={`Disabled`} placement="bottom-end">
              <Icon muted icon="disable" right />
            </Tooltip>
          )}
          {'rate_limit' in destination.config &&
            'rate_limit_period' in destination.config &&
            destination.config.rate_limit &&
            !destination.disabled_at && (
              <Tooltip
                placement="bottom-end"
                tooltip={
                  destination.config.rate_limit_period === 'concurrent'
                    ? `Max delivery rate of ${destination.config.rate_limit} concurrent`
                    : `Max delivery rate of ${destination.config.rate_limit} per ${destination.config.rate_limit_period}`
                }>
                <Icon icon="rate_limit" right muted />
              </Tooltip>
            )}
        </Div>
      </ClickableArea>
    </StyledCard>
  );
};

export default DestinationCard;
