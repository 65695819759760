import { useContext, useEffect, useState } from 'react';

import { Grid, GridUnit } from '@hookdeck/theme';

import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Text from '../../../../common/base/Text';
import DisplayDate from '../../../../common/DisplayDate';
import { Div } from '../../../../common/helpers/StyledUtils';
import Table from '../../../../common/Table';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import Tabs from '../../../../common/base/Tabs';
import JSONBrowser from '../../../../common/JSONBrowser';
import { EventListResults } from '../../../../hooks/useEventList';
import { APIWebhook } from '../../../../../../../../typings/Webhook.interface';
import { Event } from '../../../../../../../../typings/Event.interface';
import { Status } from '../../../../common/Status';
import { getErrorCodeLabels } from '../../../../../utils/error-codes';
import { DashboardContext } from '../../DashboardContext';
import { useToasts } from '../../../../common/Toast';
import useSWR from 'swr';
import APIMethodKeys from '../../../../../client/APIMethodKeys';
import Skeleton from '../../../../common/base/Skeleton';
import Icon from '../../../../common/base/Icon';
import { RequestListResult } from '../../../../hooks/useRequestList';
import { cause_filter_breakdown } from '../../../../../utils/rejection-causes';
import { Request } from '../../../../../../../../typings/Request.interface';
import ApiModal from '../ApiModal';

const PreviewEvents: React.FC<{
  context: { webhook: APIWebhook; events_list: EventListResults; requests_list: RequestListResult };
  mutateContext: (context: { event?: Event; request?: Request; webhook?: APIWebhook }) => void;
}> = ({ context: { webhook, events_list, requests_list }, mutateContext }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const [active_tab, setActiveTab] = useState('response');
  const { addToast } = useToasts();
  const [show_api_modal, showAPIModal] = useState(false);

  const [selected_event_id, setSelectedEventId] = useState<string | null>(null);

  const selected_event =
    selected_event_id?.indexOf('req_') === 0
      ? null
      : events_list?.events.find(
          (event) => event.id === (selected_event_id || events_list?.events[0]?.id),
        );

  const { data: attempt_results, mutate: mutateAttempts } = useSWR(
    selected_event && APIMethodKeys.attempts.list({ event_id: selected_event.id }),
    () => HookdeckAPI.attempts.list({ event_id: selected_event!.id }),
  );

  const attempt_to_fetch = attempt_results?.models[0];
  const { data: attempt, mutate } = useSWR(
    attempt_to_fetch && APIMethodKeys.attempts.get(attempt_to_fetch.id),
    () => HookdeckAPI.attempts.get(attempt_to_fetch!.id),
  );

  const request_with_ignored_events =
    requests_list?.requests
      .filter((request) => request.ignored_count && request.ignored_count > 0)
      .map((request) => request.id) || [];

  const { data: ignored_events } = useSWR(
    request_with_ignored_events &&
      APIMethodKeys.ignored_events.list({ request_id: request_with_ignored_events }),
    () => HookdeckAPI.ignored_events.list({ request_id: request_with_ignored_events }),
  );

  const select_ignored_event = ignored_events?.models.find(
    (event) => event.id === selected_event_id,
  );

  useEffect(() => {
    if (attempt_results !== undefined) {
      mutateAttempts();
    }
  }, [selected_event]);

  const rows =
    [...(events_list?.events || []), ...(ignored_events?.models || [])]
      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
      .map((event_or_ignored_event) => {
        if ('cause' in event_or_ignored_event) {
          const ignored_event = event_or_ignored_event;
          return {
            id: ignored_event.id,
            selected: !!selected_event_id && ignored_event.id === selected_event_id,
            fields: [
              <DisplayDate key={ignored_event.id} date={ignored_event.created_at} />,
              <Div
                key={`${ignored_event.id}-status`}
                flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
                <Badge small icon="block" muted monospace>
                  {cause_filter_breakdown[ignored_event.cause](ignored_event).label}
                </Badge>
                <Button.Permission
                  role="member"
                  small
                  minimal
                  icon="retry"
                  muted
                  m={{ y: -1.5 }}
                  onClick={(e) => {
                    setSelectedEventId(ignored_event.id);
                    e.stopPropagation();
                    HookdeckAPI.requests
                      .retry(ignored_event.request_id, [ignored_event.webhook_id])
                      .then(({ request, events }) => {
                        if (events.length === 0) {
                          addToast(
                            'info',
                            'Retried but no event was generated. Please check your rules.',
                          );
                        } else {
                          addToast('success', 'Retried and a new event was created successfully.');
                          mutateContext({
                            event: events[0],
                            request: request,
                          });
                        }
                      });
                  }}
                />
              </Div>,
              null,
            ],
          };
        }
        const event = event_or_ignored_event;
        return {
          id: event.id,
          selected: !!selected_event && event.id === selected_event.id,
          fields: [
            <DisplayDate key={event.id} date={event.created_at} />,
            <Div
              key={`${event.id}-status`}
              flex={{ gap: 2, justify: 'space-between', align: 'center' }}>
              <Status small key={event.id} {...event} />
              {event.status !== 'QUEUED' && webhook && (
                <Button.Permission
                  role="member"
                  small
                  minimal
                  icon="retry"
                  muted
                  m={{ y: -1.5 }}
                  onClick={(e) => {
                    setSelectedEventId(event.id);
                    e.stopPropagation();
                    HookdeckAPI.events.retry(event.id).then((updated_event) => {
                      addToast('success', 'Retrying event delivery');
                      mutateContext({
                        event: updated_event,
                      });
                    });
                  }}
                />
              )}
            </Div>,
            event.next_attempt_at && <DisplayDate date={event.next_attempt_at} />,
          ],
        };
      }) || [];

  return (
    <>
      <Grid>
        <GridUnit size={2 / 3}>
          <StyledCard overflow_hidden>
            {rows.length > 0 ? (
              <StyledCardSection h={{ px: 398 }}>
                <Table
                  widths={[1 / 3, 1 / 3, 1 / 4]}
                  headers={[
                    `Event Date (${getCurrentTimezoneAbreviation()})`,
                    'Event Status',
                    'Next Delivery Attempt',
                  ]}
                  rows={rows}
                  onRowSelected={setSelectedEventId}
                  has_new_results={events_list.has_next_results}
                />
              </StyledCardSection>
            ) : (
              <StyledCardSection
                muted
                flex={{
                  align: 'center',
                  justify: 'center',
                  direction: 'column',
                  grow: true,
                }}
                style={{ position: 'relative' }}
                h={{ px: 398 }}>
                <Icon icon="loading" m={{ b: 4 }} />
                <Text subtitle>Waiting on your events</Text>
              </StyledCardSection>
            )}
          </StyledCard>
        </GridUnit>
        {selected_event && (
          <GridUnit size={1 / 3}>
            <Div m={{ l: 4 }}>
              <StyledCard h={{ px: 400 }} flex={{ direction: 'column', grow: true }}>
                <StyledCardSection muted p={{ y: 1, x: 4 }}>
                  <Text muted size="s">
                    Event
                  </Text>
                </StyledCardSection>
                {selected_event.error_code ? (
                  <>
                    <StyledCardSection p={{ x: 4, y: 3 }}>
                      <Text subtitle size="s" m={{ b: 1 }}>
                        Error
                      </Text>
                      <Badge danger subtle>
                        {selected_event.error_code}
                      </Badge>
                    </StyledCardSection>
                    <StyledCardSection
                      p={{ x: 4, y: 3 }}
                      flex={{ direction: 'column', grow: true }}>
                      <Text subtitle size="s" m={{ b: 1 }}>
                        Description
                      </Text>
                      <Text size="s">
                        {getErrorCodeLabels(subscription)[selected_event.error_code]}
                      </Text>
                    </StyledCardSection>
                  </>
                ) : (
                  <>
                    <StyledCardSection p={{ t: 1.5, x: 2 }}>
                      <Tabs
                        compact
                        border={false}
                        active_tab={active_tab}
                        onTabSelected={(key) => setActiveTab(key)}
                        tabs={[
                          { key: 'response', label: 'Response' },
                          { key: 'request', label: 'Request' },
                        ]}
                      />
                    </StyledCardSection>{' '}
                    {active_tab === 'response' && (
                      <StyledCardSection scroll p={4} flex={{ grow: true, direction: 'column' }}>
                        {(attempt_to_fetch && !attempt) || attempt?.body === 'NOT_AVAILABLE_YET' ? (
                          <Skeleton h={{ px: 200 }} w={100} loading />
                        ) : !attempt ? (
                          <Text muted>
                            Response will be shown once the delivery attempt has been made.
                          </Text>
                        ) : (
                          <JSONBrowser json={(attempt?.body as any) || ''} compact />
                        )}
                      </StyledCardSection>
                    )}
                    {active_tab === 'request' && (
                      <StyledCardSection scroll p={4} flex={{ grow: true, direction: 'column' }}>
                        <JSONBrowser json={selected_event!.data?.body || null} compact />
                      </StyledCardSection>
                    )}
                  </>
                )}
              </StyledCard>
            </Div>
          </GridUnit>
        )}
        {select_ignored_event && (
          <GridUnit size={1 / 3}>
            <Div m={{ l: 4 }}>
              <StyledCard h={{ px: 400 }} flex={{ direction: 'column', grow: true }}>
                <StyledCardSection muted p={{ y: 1, x: 4 }}>
                  <Text muted size="s">
                    Ignored Event
                  </Text>
                </StyledCardSection>
                <StyledCardSection p={{ x: 4, y: 3 }}>
                  <Text subtitle size="s" m={{ b: 1 }}>
                    Cause
                  </Text>
                  <Badge icon="block" muted monospace>
                    {cause_filter_breakdown[select_ignored_event.cause](select_ignored_event).label}
                  </Badge>
                </StyledCardSection>
                <StyledCardSection p={{ x: 4, y: 3 }} flex={{ direction: 'column', grow: true }}>
                  <Text subtitle size="s" m={{ b: 1 }}>
                    Description
                  </Text>
                  <Text size="s">
                    {cause_filter_breakdown[select_ignored_event.cause](select_ignored_event).text}
                  </Text>
                </StyledCardSection>
              </StyledCard>
            </Div>
          </GridUnit>
        )}
      </Grid>
      <Div flex={{ gap: 3 }} m={{ t: 10 }}>
        <Button icon="success" disabled={events_list?.count === 0} to={'/events'}>
          Confirm Connection
        </Button>
        <Button minimal icon="code" onClick={() => showAPIModal(true)}>
          Use API
        </Button>
        {show_api_modal && (
          <ApiModal
            action="list-events"
            onClose={() => showAPIModal(false)}
            values={{
              webhook_id: webhook.id,
            }}
          />
        )}
      </Div>
    </>
  );
};

export default PreviewEvents;
