import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import useSWR, { KeyedMutator, useSWRConfig } from 'swr';

import { APIList } from '../../../../../../../typings/API.interface';
import { APIDestination } from '../../../../../../../typings/Destination.interface';
import { TransformReference } from '../../../../../../../typings/Ruleset.interface';
import { APISource } from '../../../../../../../typings/Source.interface';
import { APIWebhook } from '../../../../../../../typings/Webhook.interface';
import APIMethodKeys from '../../../../client/APIMethodKeys';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { DashboardContext } from '../DashboardContext';

export type ResourceKey = 'sources' | 'destinations' | 'webhooks';
type DeletableResource = APIWebhook | APIDestination | APISource;
type Resource = DeletableResource;
type MutationMethods = {
  property: string;
  data: APIList<any> | undefined;
  mutate: KeyedMutator<APIList<any>>;
};

export type IResourcesContext = {
  mutateResourceType?: (resource_key: ResourceKey, resource: Resource, deleted?: boolean) => void;
  resource_mutation_methods?: {
    webhooks: MutationMethods;
    sources: MutationMethods;
    destinations: MutationMethods;
  };
  webhooks?: APIList<APIWebhook>;
  destinations?: APIList<APIDestination>;
  sources?: APIList<APISource>;
  setSearchTerm?: (term: string) => void;
  search_term?: string;
  order_by?: keyof typeof order_by_options;
  setOrderBy?: (by: keyof typeof order_by_options) => void;
  group_by?: keyof typeof group_by_options;
  setGroupBy?: (by: keyof typeof group_by_options) => void;
};

export const ResourcesContext = createContext<IResourcesContext>({});

const filterToAPIQuery = (filters: { status?: 'disabled' | 'paused' | 'active' }) => {
  if (!filters) {
    return {};
  }
  const api_filters: any = { ...filters };
  if (api_filters.status) {
    if (api_filters.status === 'paused') {
      api_filters.paused_at = { any: true };
    } else if (filters.status === 'disabled') {
      api_filters.disabled_at = { any: true };
    } else if (filters.status === 'active') {
      api_filters.disabled_at = null;
      api_filters.paused_at = null;
    } else {
      api_filters.disabled = true;
    }
    delete api_filters.status;
  } else {
    api_filters.disabled = true;
  }
  return api_filters;
};

export const group_by_options = {
  source: {
    label: 'Source',
    icon: 'source',
  },
  destination: {
    label: 'Destination',
    icon: 'destination',
  },
} as const;

export const order_by_options = {
  last_updated: {
    label: 'Last Updated',
    order_by: {
      source: ['sources.updated_at', 'updated_at'],
      destination: ['destinations.updated_at', 'updated_at'],
      none: 'updated_at',
    },
    dir: 'desc',
  },
  newest: {
    label: 'Newest -> Oldest',
    order_by: {
      source: ['sources.created_at', 'created_at'],
      destination: ['destinations.created_at', 'created_at'],
      none: 'created_at',
    },
    dir: 'desc',
  },
  oldest: {
    label: 'Oldest -> Newest',
    order_by: {
      source: ['sources.created_at', 'created_at'],
      destination: ['destinations.created_at', 'created_at'],
      none: 'created_at',
    },
    dir: 'asc',
  },
} as const;

export const ResourcesContextProvider: React.FC<{
  filters: { status?: 'disabled' | 'paused' | 'active' };
  children: React.ReactNode | ((context: IResourcesContext) => React.ReactNode);
}> = ({ children, filters }) => {
  const api_filters = filterToAPIQuery(filters);
  const { HookdeckAPI } = useContext(GlobalContext);
  const { team } = useContext(DashboardContext);
  const [search_term, setSearchTerm] = useState('');
  const { mutate: globalMutate } = useSWRConfig();
  const [order_by, setOrderBy] = useLocalStorage<keyof typeof order_by_options>(
    `pref:sort_connections`,
    'newest',
  );

  const [group_by, setGroupBy] = useLocalStorage<keyof typeof group_by_options>(
    `pref:group_by_connections`,
    'source',
  );

  const getSearchPropertyFromTerm = (term: string) => {
    if (term.startsWith('web_')) return 'id';
    if (term.startsWith('src_')) return 'source_id';
    if (term.startsWith('des_')) return 'destination_id';

    return 'full_name';
  };

  const formatted_filters = {
    limit: 200,
    order_by: order_by_options[order_by].order_by[group_by],
    dir: order_by_options[order_by].dir,
    ...api_filters,
  };
  if (search_term) {
    const search_property = getSearchPropertyFromTerm(search_term);
    formatted_filters[search_property] = search_term;
  }

  const { data: webhooks, mutate: mutateWebhooks } = useSWR(
    () => APIMethodKeys.webhooks.list(formatted_filters),
    () => HookdeckAPI.webhooks.list(formatted_filters),
  );
  const { data: transformations, mutate: mutateTransformations } = useSWR(
    () => APIMethodKeys.transformations.list({ name: undefined }),
    () => HookdeckAPI.transformations.list({ name: undefined }),
  );

  const { data: destinations, mutate: mutateDestinations } = useSWR(
    APIMethodKeys.destinations.list(),
    () => HookdeckAPI.destinations.list(),
  );
  const { data: sources, mutate: mutateSources } = useSWR(APIMethodKeys.sources.list(), () =>
    HookdeckAPI.sources.list(),
  );

  const resource_mutation_methods = useMemo(
    () => ({
      webhooks: {
        property: 'webhook',
        data: webhooks,
        mutate: mutateWebhooks,
      },
      sources: {
        property: 'source',
        data: sources,
        mutate: mutateSources,
      },
      destinations: {
        property: 'destination',
        data: destinations,
        mutate: mutateDestinations,
      },
    }),
    [destinations, mutateDestinations, sources, mutateSources, webhooks, mutateWebhooks],
  );

  const mutateWebhooksResource = useCallback(
    (resource_key: Exclude<ResourceKey, 'webhooks'>, resource: APIDestination | APISource) => {
      const property = resource_mutation_methods[resource_key].property;
      const models = webhooks!.models.map((webhook) =>
        webhook[property].id === resource.id ? { ...webhook, [property]: resource } : webhook,
      );

      return mutateWebhooks(
        {
          ...webhooks!,
          models,
        },
        true,
      );
    },
    [mutateWebhooks, resource_mutation_methods, webhooks],
  );

  const mutateResourceType = useCallback(
    async (
      resource_key: ResourceKey,
      resource: APIWebhook | APIDestination | APISource,
      deleted = false,
      include_auth = true,
    ) => {
      const { mutate } = resource_mutation_methods[resource_key];
      if (!mutate || !resource) {
        return;
      }

      if (resource_key === 'sources' || resource_key === 'destinations') {
        const key = include_auth
          ? APIMethodKeys[resource_key].get(resource.id, { include: 'config.auth' })
          : APIMethodKeys[resource_key].get(resource.id);
        await globalMutate([key, team!.id], resource);
      }

      if (resource_key === 'webhooks') {
        if ('source' in resource) {
          mutateResourceType('sources', resource.source, false, false);
        }
        if ('destination' in resource) {
          mutateResourceType('destinations', resource.destination, false, false);
        }
        if ('rules' in resource) {
          const transformation_rule = resource.rules.find((rule) => rule.type === 'transform') as
            | TransformReference
            | undefined;
          if (
            transformation_rule &&
            !transformations?.models?.some(
              (transformation) => transformation_rule.transformation_id === transformation.id,
            )
          ) {
            mutateTransformations();
          }
        }
      } else {
        mutateWebhooksResource(resource_key, resource as APIDestination | APISource);
      }

      return mutate((data) => {
        let models = data.models;
        if (deleted) {
          models = data.models.filter((model) => model.id !== resource.id);
        } else {
          const index = data.models.findIndex((model) => model.id === resource.id);
          if (index >= 0) {
            models[index] = resource;
          } else {
            models = [resource, ...data.models];
          }
        }

        return {
          count: models.length,
          pagination: {
            ...data.pagination,
            prev: models[0]?.id,
            next: models[models.length - 1]?.id,
          },
          models,
        };
      }, true);
    },
    [
      resource_mutation_methods,
      transformations?.models,
      mutateTransformations,
      mutateWebhooksResource,
    ],
  );

  const context = useMemo(
    () => ({
      mutateResourceType,
      resource_mutation_methods,
      webhooks,
      sources,
      destinations,
      setSearchTerm,
      search_term,
      setOrderBy,
      order_by,
      setGroupBy,
      group_by,
    }),
    [
      mutateResourceType,
      resource_mutation_methods,
      webhooks,
      sources,
      destinations,
      group_by,
      order_by,
      search_term,
    ],
  );

  return (
    <ResourcesContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ResourcesContext.Provider>
  );
};
