import { memo, useContext } from 'react';
import NumberFormat from 'react-number-format';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../../../client/APIMethodKeys';
import { numberWithCommas } from '../../../../../../utils';
import { isFreePlan } from '../../../../../../utils/subscription';
import Divider from '../../../../../common/base/Divider';
import Loading from '../../../../../common/base/Loading';
import Text from '../../../../../common/base/Text';
import { Div } from '../../../../../common/helpers/StyledUtils';
import { GlobalContext } from '../../../../../contexts/GlobalContext';
import { OrbPlanPrice, OrbSubscription } from '../../../../../../typings/Orb.interface';
import { DashboardContext } from '../../../DashboardContext';

const StyledProgressBar = styled.div<{ base_ratio: number; overage_ratio: number }>(
  ({ theme, base_ratio, overage_ratio }) => css`
    height: 8px;
    width: 100%;
    border-radius: ${theme.radius.large};
    background-color: ${theme.colors.surface.base.variant_surface_2};
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      width: ${base_ratio * 100}%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: ${theme.colors.surface.base.primary};
      border-radius: ${theme.radius.large} ${overage_ratio > 0 ? 0 : theme.radius.large}
        ${overage_ratio > 0 ? 0 : theme.radius.large} ${theme.radius.large};
    }
    &::after {
      content: '';
      width: ${overage_ratio * 100}%;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: ${theme.colors.surface.base.warning};
      border-radius: 0 ${theme.radius.large} ${theme.radius.large} 0;
    }
  `,
);

const EventsUsage: React.FC<{ subscription_details: OrbSubscription }> = ({
  subscription_details,
}) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { subscription } = useContext(DashboardContext);
  const { data: usage_data } = useSWR(
    APIMethodKeys.billing.getUsage({
      timeframe_start: subscription_details.current_billing_period_start_date,
      timeframe_end: subscription_details.current_billing_period_end_date,
    }),
    () =>
      HookdeckAPI.billing.getUsage({
        timeframe_start: subscription_details.current_billing_period_start_date,
        timeframe_end: subscription_details.current_billing_period_end_date,
      }),
  );

  const meters: Record<string, OrbPlanPrice> = subscription_details?.plan.prices.reduce(
    (acc, price) => {
      const is_same_plan_phase =
        !subscription_details?.plan.active_plan_phase_order ||
        subscription_details.plan.active_plan_phase_order === price.plan_phase_order;
      if (price.price_type === 'usage_price' && is_same_plan_phase) {
        return { ...acc, [price.id]: price };
      }
      return acc;
    },
    {},
  );

  const meters_usage: Array<{
    display_name: string;
    included: number;
    overage: number;
    batch_price: number;
    base_ratio: number;
    overage_ratio: number;
    usage: number;
    batch_size: number;
  }> = [];
  if (usage_data && subscription_details) {
    for (const [, price] of Object.entries(meters)) {
      const included = Number(
        price.package_with_allocation_config?.allocation || subscription?.max_requests || 0,
      );

      const package_config = price.package_with_allocation_config || price.package_config;

      const batch_size = Number(package_config?.package_size || 0);
      let batch_price = Number(package_config?.package_amount || 0);

      if (price.discount?.percentage_discount) {
        batch_price = batch_price * price.discount?.percentage_discount;
      }

      const usage = Number(
        usage_data.data.find((entry) => entry.billable_metric.id === price.billable_metric.id)
          ?.usage[0]?.quantity || 0,
      );

      const overage_batch_count = batch_size !== 0 ? Math.ceil((usage - included) / batch_size) : 0;
      const overage_ratio = usage <= included ? 0 : 1 - included / usage;
      const base_ratio = overage_ratio === 0 ? usage / included : 1 - overage_ratio;
      const overage = overage_batch_count > 0 ? (overage_batch_count * batch_price) / 100 : 0;

      meters_usage.push({
        display_name: price.name,
        included,
        overage,
        batch_price,
        base_ratio,
        overage_ratio,
        usage,
        batch_size,
      });
    }
  }

  return !meters_usage ? (
    <Loading />
  ) : (
    <Div m={{ b: 14 }}>
      <Text heading as="h2" m={0}>
        Metered Usage
      </Text>
      <Text muted as="p" m={{ b: 2 }}>
        Metered usage data is updated every hour.
      </Text>
      <Divider m={{ b: 4 }} />
      {meters_usage
        .filter((meter) => !!meter)
        .map(
          (
            {
              included,
              display_name,
              usage,
              overage,
              base_ratio,
              overage_ratio,
              batch_price,
              batch_size,
            },
            i,
          ) => (
            <Div m={{ t: i === 0 ? 0 : 6 }} key={display_name}>
              <Div flex={{ justify: 'space-between' }}>
                <Text subtitle size="s" m={{ b: 2 }} capitalize>
                  {display_name}
                </Text>
                <Text subtitle size="s" m={{ b: 2 }}>
                  <NumberFormat
                    renderText={(v) => v}
                    displayType="text"
                    value={included}
                    thousandSeparator={','}
                  />{' '}
                  Included{' '}
                  {batch_price !== 0 &&
                    ` + $${batch_price} Per ${numberWithCommas(batch_size || 1)}`}
                </Text>
              </Div>
              <StyledProgressBar base_ratio={base_ratio} overage_ratio={overage_ratio} />
              <Div m={{ t: 2 }} flex={{ justify: 'space-between' }}>
                <Text capitalize subtitle size="s" muted>
                  <NumberFormat
                    renderText={(v) => v}
                    displayType="text"
                    value={usage}
                    thousandSeparator={','}
                  />{' '}
                  {display_name}
                </Text>
                {!isFreePlan(subscription_details.plan.external_plan_id) ? (
                  <Text capitalize subtitle size="s" muted>
                    ${overage?.toFixed(2)}
                  </Text>
                ) : (
                  <Text capitalize subtitle size="s" muted>
                    <NumberFormat
                      renderText={(v) => v}
                      displayType="text"
                      value={included}
                      thousandSeparator={','}
                    />{' '}
                    Max
                  </Text>
                )}
              </Div>
            </Div>
          ),
        )}
      {meters_usage.length > 1 && meters_usage.some(({ batch_price }) => batch_price !== 0) && (
        <Div m={{ t: 4 }} flex={{ justify: 'space-between' }}>
          <Text m={{ b: 2 }} subtitle>
            Total metered usage charge at period end
          </Text>
          <Text m={{ b: 2 }} subtitle>
            <NumberFormat
              decimalScale={2}
              fixedDecimalScale={true}
              prefix={'$'}
              displayType="text"
              value={meters_usage.reduce((total, { overage }) => total + overage, 0)}
              thousandSeparator={','}
            />
          </Text>
        </Div>
      )}
    </Div>
  );
};
const MemoizedEventsUsage = memo(EventsUsage);

export default MemoizedEventsUsage;
