import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';

import { Grid, GridUnit } from '@hookdeck/theme';

import {
  Request,
  RequestRejectionCauseCompatible,
} from '../../../../../../../../typings/Request.interface';
import { APISource } from '../../../../../../../../typings/Source.interface';
import LINKS from '../../../../../configs/links';
import { useCopyToClipboard } from '../../../../../utils/copy';
import { getCurrentTimezoneAbreviation } from '../../../../../utils/date';
import { showChat } from '../../../../../utils/liveChat';
import {
  getRejectionCauseBreakdown,
  rejection_cause_labels,
} from '../../../../../utils/rejection-causes';
import Badge from '../../../../common/base/Badge';
import Button from '../../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../../common/base/Card';
import Icon from '../../../../common/base/Icon';
import Tabs from '../../../../common/base/Tabs';
import Text from '../../../../common/base/Text';
import CopyableField from '../../../../common/CopyableField';
import DisplayDate from '../../../../common/DisplayDate';
import Editor from '../../../../common/Editor';
import EditorInput from '../../../../common/Form/Fields/EditorInput';
import RadioCardsInput from '../../../../common/Form/Fields/RadioCardsInput';
import StyledEllipsisOverflow from '../../../../common/helpers/EllipsisOverflow';
import { Div } from '../../../../common/helpers/StyledUtils';
import JSONBrowser from '../../../../common/JSONBrowser';
import { KeyValueRow } from '../../../../common/KeyValueTable';
import Modal from '../../../../common/Modal';
import Table from '../../../../common/Table';
import { useToasts } from '../../../../common/Toast';
import { GlobalContext } from '../../../../contexts/GlobalContext';
import { RequestListResult } from '../../../../hooks/useRequestList';
import useSearchQuery from '../../../../hooks/useSearchQuery';
import ApiModal from '../ApiModal';

const retryable_rejection_causes = [
  'VERIFICATION_FAILED',
  'UNSUPPORTED_HTTP_METHOD',
] as RequestRejectionCauseCompatible[];

const PreviewRequests: React.FC<{
  context: { source: APISource; requests_list: RequestListResult };
  mutateContext: (context: { source?: APISource; request?: Request }) => void;
  nextStep: () => void;
  is_current_step: boolean;
}> = ({ context: { source, requests_list }, mutateContext, nextStep, is_current_step }) => {
  const { HookdeckAPI } = useContext(GlobalContext);
  const { query, updateSearchQuery } = useSearchQuery<{
    troubleshoot?: boolean;
  }>();
  const [show_api_modal, showAPIModal] = useState(false);
  const [active_tab, setActiveTab] = useState('body');
  const copyToClipboard = useCopyToClipboard();
  const { addToast } = useToasts();

  const [selected_request_id, setSelectedRequestId] = useState<string | null>(null);

  const selected_request = requests_list?.requests.find(
    (request) => request.id === (selected_request_id || requests_list?.requests[0]?.id),
  );

  const rows =
    requests_list?.requests.map((request) => ({
      id: request.id,
      selected: selected_request && request.id === selected_request.id,
      fields: [
        <DisplayDate key={request.id} date={request.ingested_at} />,
        request.rejection_cause && request.rejection_cause !== 'NO_CONNECTION' ? (
          <Div key={request.id} flex={{ justify: 'space-between', align: 'center' }}>
            <Badge danger subtle small>
              <StyledEllipsisOverflow>
                {rejection_cause_labels[request.rejection_cause]}
              </StyledEllipsisOverflow>
            </Badge>
            <Div m={{ y: -1.5 }}>
              {retryable_rejection_causes.includes(request.rejection_cause) && (
                <Button.Permission
                  role="member"
                  small
                  minimal
                  icon="retry"
                  muted
                  onClick={(e) => {
                    e.stopPropagation();
                    HookdeckAPI.requests.retry(request.id).then(({ request: updated_request }) => {
                      const rejected =
                        updated_request.rejection_cause &&
                        updated_request.rejection_cause !== 'NO_CONNECTION';
                      addToast(
                        rejected ? 'error' : 'success',
                        rejected
                          ? 'Request retried and is still invalid'
                          : 'Request has been accepted',
                      );
                      mutateContext({
                        request: updated_request,
                      });
                    });
                  }}
                />
              )}
            </Div>
          </Div>
        ) : (
          <Badge success subtle small>
            Accepted
          </Badge>
        ),
      ],
    })) || [];

  const cURL_command = `curl -X POST ${source?.url} \\
-H "Content-Type: application/json" \\
-d '{ "hello": "world"}'`;

  return (
    <>
      {query.troubleshoot && (
        <Modal
          submit_label="Help"
          submit_icon="chat"
          onSubmit={showChat}
          title="Request Troubleshooting"
          onClose={() => updateSearchQuery({}, { remove_keys: ['troubleshoot'] })}>
          <Text m={{ b: 4 }}>Your requests may not be showing for a variety of reasons:</Text>
          <StyledCard p={4} m={{ t: 4 }}>
            <Text heading m={{ b: 2 }}>
              The sender implements a challenge to verify the Webhook URL
            </Text>
            <Text muted>
              Some webhook providers implement custom handshakes to verify the URL (also know as
              "Challenges"). Hookdeck will implement support for these handshakes on an ad-hoc basis
              when they are requested.{' '}
              <a href={LINKS.product_docs.inbuilt_source_support} target="_blank" rel="noreferrer">
                View the current list of supported platforms
              </a>
              .
              <br />
              <Button outline m={{ t: 3 }} onClick={showChat}>
                Request a new one
              </Button>
            </Text>
          </StyledCard>
          <StyledCard p={4} m={{ t: 4 }}>
            <Text heading m={{ b: 2 }}>
              The sender requires whitelisting our IP
            </Text>
            <Text muted>
              In some rare cases the providers might require you to whitelist Hookdeck IP's to send
              requests to our servers. You can whitelist Cloudflare IPs which are found{' '}
              <a href="http://cloudflare.com/ips/" target="_blank" rel="noreferrer">
                here
              </a>
              .
            </Text>
          </StyledCard>
        </Modal>
      )}
      <Formik
        initialValues={{
          request_source: 'url',
          mock_request_body: JSON.stringify({ hello: 'world' }, null, 2),
        }}
        onSubmit={(v) => {
          return fetch(source.url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: v.mock_request_body,
          }).then((response) => {
            addToast('success', 'Request sent successfully');
          });
        }}>
        {({ values, isSubmitting }) => (
          <Form>
            <Grid>
              <GridUnit size={2 / 3}>
                <RadioCardsInput
                  m={{ b: 8 }}
                  name="request_source"
                  options={[
                    {
                      label: 'Webhook URL',
                      value: 'url',
                      description: 'Give a URL to your source.',
                    },
                    {
                      label: 'cURL',
                      value: 'curl',
                      description: 'Make a request via cURL.',
                    },
                    {
                      label: 'Mock Request',
                      value: 'mock',
                      description: 'Send a simulated request.',
                    },
                  ]}
                />
                <Div flex={{ gap: 3 }}>
                  <Div>
                    <Badge muted>1</Badge>
                  </Div>
                  {values.request_source === 'curl' && (
                    <Div flex={{ grow: true, direction: 'column' }}>
                      <Text subtitle>Run cURL command in your terminal</Text>
                      <Text muted m={{ b: 4 }}>
                        Run the below command to simulate a request from your source into Hookdeck.
                      </Text>
                      <StyledCard overflow_hidden>
                        <StyledCardSection
                          muted
                          p={{ l: 4, r: 1.5, y: 0.5 }}
                          flex={{ justify: 'space-between', align: 'center' }}>
                          <Text subtitle>cURL</Text>
                          <Button
                            minimal
                            icon="copy"
                            small
                            onClick={() => copyToClipboard(cURL_command)}
                            on="background"
                          />
                        </StyledCardSection>
                        <StyledCardSection>
                          <Editor
                            height="80px"
                            display="minimal"
                            language="text"
                            prevent_theme_change
                            value={cURL_command}
                          />
                        </StyledCardSection>
                      </StyledCard>
                    </Div>
                  )}
                  {values.request_source === 'url' && (
                    <Div flex={{ grow: true, direction: 'column' }}>
                      <Text subtitle>Copy and paste Hookdeck URL into your request source</Text>
                      <Text muted m={{ b: 4 }}>
                        HTTP requests from your source will be associated to the unique Hookdeck URL
                        below.
                      </Text>
                      <CopyableField value={source?.url} monospace />
                    </Div>
                  )}
                  {values.request_source === 'mock' && (
                    <Div flex={{ grow: true, direction: 'column' }}>
                      <Text subtitle>Send a simulated request</Text>
                      <Text muted m={{ b: 4 }}>
                        Simulate a HTTP request with the body bellow from your source into Hookdeck.
                      </Text>
                      <StyledCard overflow_hidden>
                        <StyledCardSection
                          muted
                          p={{ l: 4, r: 1.5, y: 1.5 }}
                          flex={{ justify: 'space-between', align: 'center' }}>
                          <Text subtitle>Request Body</Text>
                        </StyledCardSection>
                        <StyledCardSection>
                          <EditorInput
                            height={`${values.mock_request_body.split('\n').length * 26}px`}
                            display="minimal"
                            language="json"
                            name="mock_request_body"
                          />
                        </StyledCardSection>
                      </StyledCard>
                      <div>
                        <Button
                          icon={isSubmitting ? 'loading' : 'send'}
                          disabled={isSubmitting}
                          submit
                          outline
                          m={{ t: 4 }}>
                          Send Request
                        </Button>
                      </div>
                    </Div>
                  )}
                </Div>
              </GridUnit>
            </Grid>
            <Div m={{ t: 8 }}>
              <Grid>
                <GridUnit size={2 / 3}>
                  <Div flex={{ gap: 3 }}>
                    <Div>
                      <Badge muted>2</Badge>
                    </Div>
                    <Div flex={{ grow: true, direction: 'column' }}>
                      <Text subtitle>View requests</Text>
                      <Text muted m={{ b: 4 }}>
                        Inspect the contents of HTTP requests from your source.{' '}
                      </Text>
                      <StyledCard overflow_hidden>
                        {rows.length > 0 ? (
                          <StyledCardSection h={{ px: 398 }}>
                            <Table
                              widths={[1 / 2, 1 / 2]}
                              headers={[
                                `Request Date (${getCurrentTimezoneAbreviation()})`,
                                'Status',
                              ]}
                              rows={rows}
                              onRowSelected={(id) => setSelectedRequestId(id)}
                              has_new_results={requests_list.has_next_results}
                            />
                          </StyledCardSection>
                        ) : (
                          <StyledCardSection
                            muted
                            flex={{
                              align: 'center',
                              justify: 'center',
                              direction: 'column',
                              grow: true,
                            }}
                            style={{ position: 'relative' }}
                            h={{ px: 398 }}>
                            <Icon icon="loading" m={{ b: 4 }} />
                            <Text subtitle>Waiting on your first request</Text>
                            <Div
                              style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
                              flex={{ justify: 'center' }}
                              m={{ b: 4 }}>
                              <Button
                                minimal
                                icon="help"
                                muted
                                on="background"
                                onClick={() => updateSearchQuery({ troubleshoot: true })}
                                small>
                                I don't see my requests
                              </Button>
                            </Div>
                          </StyledCardSection>
                        )}
                      </StyledCard>
                    </Div>
                  </Div>
                </GridUnit>
                {selected_request && (
                  <GridUnit size={1 / 3}>
                    <Div m={{ l: 4, t: 14 }}>
                      <StyledCard h={{ px: 400 }} flex={{ direction: 'column', grow: true }}>
                        <StyledCardSection muted p={{ y: 1, x: 4 }}>
                          <Text subtitle muted size="s">
                            Request
                          </Text>
                        </StyledCardSection>
                        {selected_request.rejection_cause &&
                        selected_request.rejection_cause !== 'NO_CONNECTION' ? (
                          <>
                            <StyledCardSection p={{ x: 4, y: 3 }}>
                              <Text subtitle size="s" m={{ b: 1 }}>
                                Error
                              </Text>
                              <Badge danger subtle>
                                {rejection_cause_labels[selected_request.rejection_cause]}
                              </Badge>
                            </StyledCardSection>
                            <StyledCardSection
                              p={{ x: 4, y: 3 }}
                              flex={{ direction: 'column', grow: true }}>
                              <Text subtitle size="s" m={{ b: 1 }}>
                                Description
                              </Text>
                              <Text size="s">
                                {
                                  getRejectionCauseBreakdown(selected_request.rejection_cause)({
                                    source,
                                    request: selected_request,
                                  }).text
                                }
                              </Text>
                              <Div m={{ t: 'auto' }}>
                                {source.config.allowed_http_methods &&
                                  selected_request.rejection_cause ===
                                    'UNSUPPORTED_HTTP_METHOD' && (
                                    <Button
                                      outline
                                      onClick={() =>
                                        HookdeckAPI.sources
                                          .update(source.id, {
                                            allowed_http_methods: Array.from(
                                              new Set([
                                                ...(source.config.allowed_http_methods as string[]),
                                                selected_request!.data?.headers[
                                                  'x-hookdeck-original-method'
                                                ] || 'POST',
                                              ]),
                                            ),
                                          })
                                          .then((source) => {
                                            HookdeckAPI.requests
                                              .retry(selected_request.id)
                                              .then(({ request: updated_request }) => {
                                                addToast(
                                                  'success',
                                                  'Method enabled and request retried successfully',
                                                );
                                                mutateContext({ request: updated_request });
                                              });
                                            mutateContext({ source });
                                          })
                                      }>
                                      Enable{' '}
                                      {selected_request!.data?.headers[
                                        'x-hookdeck-original-method'
                                      ]?.toUpperCase() || 'POST'}
                                    </Button>
                                  )}
                                {selected_request.rejection_cause === 'VERIFICATION_FAILED' && (
                                  <Button
                                    outline
                                    onClick={() =>
                                      HookdeckAPI.sources
                                        .update(source.id, {
                                          ...source,
                                          config: {
                                            ...source.config,
                                            auth: null,
                                            auth_type: null,
                                          },
                                        })
                                        .then((source) => {
                                          HookdeckAPI.requests
                                            .retry(selected_request.id)
                                            .then(({ request: updated_request }) => {
                                              addToast(
                                                'success',
                                                'Authentication removed and request retried successfully',
                                              );
                                              mutateContext({ request: updated_request });
                                            });
                                          mutateContext({ source });
                                        })
                                    }>
                                    Disable Authentication
                                  </Button>
                                )}
                              </Div>
                            </StyledCardSection>
                          </>
                        ) : (
                          <>
                            <StyledCardSection p={{ t: 1.5, x: 2 }}>
                              <Tabs
                                compact
                                border={false}
                                active_tab={active_tab}
                                onTabSelected={(key) => setActiveTab(key)}
                                tabs={[
                                  { key: 'body', label: 'Body' },
                                  { key: 'headers', label: 'Headers' },
                                ]}
                              />
                            </StyledCardSection>
                            {active_tab === 'body' && (
                              <StyledCardSection
                                scroll
                                p={4}
                                flex={{ grow: true, direction: 'column' }}>
                                <JSONBrowser json={selected_request!.data?.body || null} compact />
                              </StyledCardSection>
                            )}
                            {active_tab === 'headers' && (
                              <StyledCardSection scroll flex={{ grow: true, direction: 'column' }}>
                                {Object.entries(selected_request!.data?.headers || {}).map(
                                  ([key, value]) => (
                                    <KeyValueRow
                                      compact={true}
                                      key={key}
                                      _key={key}
                                      value={value}
                                      hide_value={false}
                                      effective_width={304}
                                    />
                                  ),
                                )}
                              </StyledCardSection>
                            )}
                          </>
                        )}
                      </StyledCard>
                    </Div>
                  </GridUnit>
                )}
              </Grid>
            </Div>
            <Div flex={{ gap: 3 }} m={{ t: 8 }}>
              <Button
                icon="success_circle"
                onClick={nextStep}
                disabled={
                  !is_current_step ||
                  requests_list?.requests.filter(
                    (model) =>
                      model.rejection_cause === null || model.rejection_cause === 'NO_CONNECTION',
                  ).length === 0
                }>
                Next
              </Button>
              <Button minimal icon="code" onClick={() => showAPIModal(true)}>
                Use API
              </Button>
              {show_api_modal && (
                <ApiModal
                  action="list-requests"
                  onClose={() => showAPIModal(false)}
                  values={{ source_id: source!.id }}
                />
              )}
            </Div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PreviewRequests;
